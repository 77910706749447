import React, { useState, useEffect } from 'react';
import { Container, Dropdown, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ManagerNavigationBar from './managerNavigationBar';
import './managerPages.css';
import ManagerSalesReport from './managerSalesReport';
import ManagerRestockReport from './managerRestockReport';
import ManagerPopularityReport from './managerPopularityReport';
import ManagerExcessReport from './managerExcessReport';

const ManagerTrendsPage = () => {
  const [selectedOption, setSelectedOption] = useState('sales');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');

  const handleDropdownChange = (eventKey) => {
    setSelectedOption(eventKey);
  };

  useEffect(() => {
    const fetchData = async () => {
      // Fetch data for trends if needed
    };
    fetchData();
  }, []);

  const renderReport = () => {
    switch (selectedOption) {
      case 'sales':
        return <ManagerSalesReport startTime={startTime} endTime={endTime} />;
      case 'restock':
        return <ManagerRestockReport startTime={startTime} endTime={endTime} />;
      case 'popularity':
        return <ManagerPopularityReport startTime={startTime} endTime={endTime} />;
      case 'excess':
        return <ManagerExcessReport startTime={startTime} endTime={endTime} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <ManagerNavigationBar />
      <div className='text-center'>
        <div className='d-flex justify-content-center'>
          <Container>
            <label htmlFor="reportSelector">Select a report:</label>
            <Dropdown onSelect={handleDropdownChange}>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {selectedOption || 'Select Report'}
              </Dropdown.Toggle>

              <Dropdown.Menu style={{ maxHeight: '600px', overflowY: 'auto' }}>
                <Dropdown.Item eventKey="sales">Sales Report</Dropdown.Item>
                <Dropdown.Item eventKey="restock">Restock Report</Dropdown.Item>
                <Dropdown.Item eventKey="popularity">Popularity Report</Dropdown.Item>
                <Dropdown.Item eventKey="excess">Excess Report</Dropdown.Item>
                {/* Add more Dropdown.Items for additional reports as needed */}
              </Dropdown.Menu>
            </Dropdown>

          </Container>
        </div>

        <div className='table-container'>
          {renderReport()}
        </div>
      </div>
    </div>
  );
};

export default ManagerTrendsPage;
