import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './navBar.css'

/**
 * ManagerNavigationBar class displays the navbar that is used at the top of each manager page
 * It allows a user to navigate between the home page, trends page, and tables
 * @author Sophia Evanisko
 */
const ManagerNavigationBar = () => {
  return (
    <Navbar bg="dark" variant="dark" className="justify-content-between navbar">
      <Nav className='d-flex w-100' style={{ justifyContent: 'space-between' }}>
        <Nav.Link as={Link} to="/" className="link">Home</Nav.Link>
        <Nav.Link as={Link} to="/manager" className="link">Dashboard</Nav.Link>
        <Nav.Link as={Link} to="/manager/trends" className="link">Trends</Nav.Link>
        <Nav.Link as={Link} to="/manager/tables" className="link">Tables</Nav.Link>
      </Nav>
    </Navbar>
  );
};

export default ManagerNavigationBar;