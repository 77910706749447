import React, { useState } from 'react';
import './itemPage.css';
import ItemPopupCustomer from './ItemPopupCustomer'; 

/**
 * The Item component handles the characteristics for the given item
 * @param {object} category - Category passed in from ItemPage
 * @param {object} item - Item passed in from ItemPage
 * @author Maria Visinescu
 * @author Patralika Ghosh
 */
const Item = ({ category, item }) => {
  // Hooks for state management
  const [showPopup, setShowPopup] = useState(false);
  const [selectedAdditionalIngredients, setSelectedAdditionalIngredients] = useState([]); // Added state for selectedAdditionalIngredients
  const [modifications, setModifications] = useState([]); // Added state for modifications
  let currentCart = JSON.parse(sessionStorage.getItem('cart')) || [];
  let currentCartID = JSON.parse(sessionStorage.getItem('cartID')) || [];

  /**
   * Function to show the pop-up
   */
  const openPopup = () => {
    setShowPopup(true);
  };

  /**
   * Function to close the pop-up
   */
  const closePopup = () => {
    setShowPopup(false);
  };

  /**
   * Function to add the selected item to the cart
   * @param {object} ItemObject - selected item
   */
  const addToCart = (ItemObject) => {
    // Access values from the menuItemObject
    const { name, id, selectedAdditionalIngredients, modifications, price, modifications_strings } = ItemObject;

    // Add the selected item to the cart
    currentCart.push(name);

    const menuItemObject = {
      "name": name,
      "id": id,
      "price": price,
      "ingredients": selectedAdditionalIngredients,
      "modifications": modifications,
      "modifications_strings": modifications_strings,
    };
    
    // console.log('Modifications:', modifications_strings);
    currentCartID.push(menuItemObject)

    // Store the updated cart in session storage
    sessionStorage.setItem('cart', JSON.stringify(currentCart));
    sessionStorage.setItem('cartID', JSON.stringify(currentCartID));

    // console.log('Current Cart ID:', currentCartID);
    // console.log('Current Cart:', currentCart);

    // Display a message to confirm the item was added to the cart
    alert(`Added "${name}" to the cart.`);

  }

  // Fetches the data
  let apiUrl;
  const formattedString = item.name.toLowerCase().replace(/\s+/g, '-');

  if (process.env.NODE_ENV === 'development') {
    // Development environment
    apiUrl = `http://localhost:8000/static/${category}/${formattedString}.jpg`;
  } else {
    // Production environment
    apiUrl = `https://csce315331-07b.onrender.com/static/${category}/${formattedString}.jpg`;
  }

  // URL of the default image
  const defaultImageUrl = `https://csce315331-07b.onrender.com/static/default-image.jpg`;
  
  // State to track whether an error occurred while loading the image
  const [imageError, setImageError] = useState(false);

  /**
   * Function to handle image error
   */
  const handleImageError = () => {
    // Set imageError to true, indicating that an error occurred
    setImageError(true);
  };

  // JSX for each item
  return (
    <>
      <div className="item">
        <img
          className="item-image"
          src={imageError ? defaultImageUrl : apiUrl}
          alt={formattedString}
          onError={handleImageError}
        />
        <p className="item-description">
          <span className="bold-text">{item.name} - ${item.price}</span>
        </p>
        <p className="item-description">
          Ingredients: {item.ingredients.map((ingredient, index) => (
            // Add a comma after each ingredient except for the last one
            <span key={index}>
              {ingredient}{index !== item.ingredients.length - 1 ? ', ' : ''}
            </span>
          ))}
        </p>
        <div>
          <button className="item-button" onClick={openPopup}>
            Add to Cart
          </button>
        </div>
      </div>

      {/* Pop-up */}
      {showPopup && (
        <ItemPopupCustomer
          selectedItem={item}
          closePopup={closePopup}
          addToCart={addToCart}
          // Pass the selectedAdditionalIngredients and setModifications to the ItemPopup component
          selectedAdditionalIngredients={selectedAdditionalIngredients}
          setModifications={setModifications}
        />
      )}
    </>
  );
}
  
export default Item;
  