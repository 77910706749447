// Import only the components needed from react-bootstrap
import React, { useState, useEffect } from 'react';
import { Table, Button, Form, Row, FormCheck } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './managerPages.css';

/**
 * ManagerEmployeeTable  displays the employee table in the manager view
 * It handles adding, editing, deleting and updating the employee table and fetching the relevant data from the django backend
 * @param {object|null} employees - The Employee objects retrieved from the Django backend
 * @author Sophia Evanisko
 */
const ManagerEmployeeTable = ({ employees }) => {
    // hooks to manage the variables and state of the employee table
    const [rows, setRows] = useState(employees);
    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [newEmployee, setNewEmployee] = useState({ name: '', unionized: false, id: '' });


    const refreshTable = async () => {
        try {
            let apiUrl;
            if (process.env.NODE_ENV === 'development') {
                apiUrl = `http://localhost:8000/datamanagement/getEmployees`;
            } else {
                apiUrl = `https://csce315331-07b.onrender.com/datamanagement/getEmployees`;
            }

            const response = await fetch(apiUrl);
            const data = await response.json();
            employees = data.employees;
            setRows(data.employees);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    /**
     * Will delete the entry with the given id from the table and update the display
     * @param {*} id - the id of the entry to be deleted
     * @param {*} table - a string representing the purpose of the table i.e. "employee"
     */
    const deleteRow = async (id, table) => {
        console.log("Attempting to delete " + id);

        try {
            let apiUrl;
            if (process.env.NODE_ENV === 'development') {
                apiUrl = `http://localhost:8000/datamanagement/deleteRow/${id}/${table}`;
            } else {
                apiUrl = `https://csce315331-07b.onrender.com/datamanagement/deleteRow/${id}/${table}`;
            }

            // Make a DELETE request to your Django backend to delete the employee
            await fetch(apiUrl);
            console.log("successful");
            const updatedRows = rows.filter((employee) => employee.id !== id);
            setRows(updatedRows);
        } catch (error) {
            console.error(`Error deleting employee: ${error.message}`);
        }
    };

    /**
     * Hides the edit employee form and displays the add emplyoee form
     */
    const handleAddEmployee = () => {
        setShowAddForm(true);
        setShowEditForm(false);
    };

    /**
     * Hides the add employee form and displays the edit employee form
     */
    const handleEditEmployee = () => {
        setShowAddForm(false);
        setShowEditForm(true);
    };

    /**
     * Adds an employee to the database tables and displays the updated table
     * @param {*} event
     */
    const handleAddFormSubmit = async (event) => {
        event.preventDefault();
        // Access the name value from newEmployee state
        const { name, unionized } = newEmployee;

        try {
            let apiUrl;
            if (process.env.NODE_ENV === 'development') {
                apiUrl = `http://localhost:8000/datamanagement/addEmployee/`;
            } else {
                apiUrl = `https://csce315331-07b.onrender.com/datamanagement/addEmployee/`;
            }

            // Make a post request to Django backend to add the employee
            const response = await fetch(apiUrl, {
                method: 'POST',
                body: JSON.stringify({ name: name, unionized: unionized }),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = await response.json();

            // Reset the form and hide it
            setNewEmployee({ name: '', unionized: false });
            setShowAddForm(false);

            // After successfully adding the employee, update the local state
            refreshTable();
        } catch (error) {
            console.error(`Error adding employee: ${error.message}`);
        }
    };

    /**
     * Edits an employee currently in the database tables and displays the updated table
     * @param {*} event
     */
    const handleEditFormSubmit = async (event) => {
        event.preventDefault();
        // Access the name, unionized, and id values from newEmployee state
        const { name, unionized, id } = newEmployee;

        try {
            let apiUrl;
            if (process.env.NODE_ENV === 'development') {
                apiUrl = `http://localhost:8000/datamanagement/editEmployee/`;
            } else {
                apiUrl = `https://csce315331-07b.onrender.com/datamanagement/editEmployee/`;
            }

            // Make a POST request to your Django backend to edit the employee
            const response = await fetch(apiUrl, {
                method: 'POST',
                body: JSON.stringify({ name: name, unionized: unionized, id: id }),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            await response.json();

            // Reset the form and hide it
            setNewEmployee({ name: '', unionized: false, id: '' });
            setShowEditForm(false);

            // After successfully editing the employee, update the local state
            refreshTable();


        } catch (error) {
            console.error(`Error editing employee: ${error.message}`);
        }
    };

    /**
     * Update the local state when the employees prop changes
     */
    useEffect(() => {
        setRows(employees);
    }, [employees]);

    return (
        <div>
            <div>
                <Button variant="info" id="button-basic" onClick={() => handleAddEmployee()}>
                    Add Employee
                </Button>
                <Button variant="warning" id="button-basic" onClick={() => handleEditEmployee()}>
                    Edit Employee
                </Button>
            </div>

            <div>
                {showAddForm && (
                    <Form>
                        <Row>
                            <Form.Group style={{ display: 'flex', flexDirection: 'row' }}>
                                <Form.Control
                                    className="input-field"
                                    type="text"
                                    placeholder="Name"
                                    name="name"
                                    value={newEmployee.name}
                                    onChange={(e) => setNewEmployee({ ...newEmployee, name: e.target.value })}
                                />
                                <FormCheck
                                    className="input-field"
                                    id="add-union"
                                    type="checkbox"
                                    label="Unionized?"
                                    checked={newEmployee.unionized}
                                    onChange={(e) => setNewEmployee({ ...newEmployee, unionized: e.target.checked })}
                                />
                                <Button
                                    className="submit-button input-field"
                                    value="submit"
                                    type="submit"
                                    onClick={(e) => handleAddFormSubmit(e)}
                                >
                                    Submit
                                </Button>
                            </Form.Group>
                        </Row>
                    </Form>
                )}
            </div>

            <div>
                {showEditForm && (
                    <Form>
                        <Row>
                            <Form.Group style={{ display: 'flex', flexDirection: 'row' }}>
                                <Form.Control
                                    className="input-field"
                                    type="text"
                                    placeholder="ID"
                                    name="ID"
                                    value={newEmployee.id}
                                    onChange={(e) => setNewEmployee({ ...newEmployee, id: e.target.value })}
                                />
                                <Form.Control
                                    className="input-field"
                                    type="text"
                                    placeholder="Name"
                                    name="name"
                                    value={newEmployee.name}
                                    onChange={(e) => setNewEmployee({ ...newEmployee, name: e.target.value })}
                                />
                                <FormCheck
                                    className="input-field"
                                    id="add-union"
                                    type="checkbox"
                                    label="Unionized?"
                                    checked={newEmployee.unionized}
                                    onChange={(e) => setNewEmployee({ ...newEmployee, unionized: e.target.checked })}
                                />
                                <Button
                                    className="submit-button input-field"
                                    value="submit"
                                    type="submit"
                                    onClick={(e) => handleEditFormSubmit(e)}
                                >
                                    Submit
                                </Button>
                            </Form.Group>
                        </Row>
                    </Form>
                )}
            </div>

            <div className="scrollable-tbody">
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Unionized?</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody className="scrollable-tbody">
                        {rows.map((employee) => (
                            <tr key={employee.id}>
                                <td>{employee.id}</td>
                                <td>{employee.name}</td>
                                <td>{employee.unionized.toString()}</td>
                                <td>
                                    <Button variant="danger" onClick={() => deleteRow(employee.id, "employee")}>
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export default ManagerEmployeeTable;
