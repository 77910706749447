import React, { useState } from 'react';
import './menuPage.css';

const MenuItem = ({ category, item, fontSize }) => {

  let apiUrl;
  const formattedString = item.name.toLowerCase().replace(/\s+/g, '-');

  if (process.env.NODE_ENV === 'development') {
    // Development environment
    apiUrl = `http://localhost:8000/static/${category}/${formattedString}.jpg`;
  } else {
    // Production environment
    apiUrl = `https://csce315331-07b.onrender.com/static/${category}/${formattedString}.jpg`;
  }

  const [showIngredients, setShowIngredients] = useState(false);

  const style = {
    fontSize: `${fontSize + 2}px` 
  };

  const handleMouseEnter = () => {
    setShowIngredients(true);
  };

  const handleMouseLeave = () => {
    setShowIngredients(false);
  }
  // URL of the default image
  const defaultImageUrl = `https://csce315331-07b.onrender.com/static/default-image.jpg`;
  
  // State to track whether an error occurred while loading the image
  const [imageError, setImageError] = useState(false);

  /**
   * Function to handle image error
   */
  const handleImageError = () => {
    // Set imageError to true, indicating that an error occurred
    setImageError(true);
  };

  // JSX for each item
  return (
    <div className="item" style={style} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <img className="item-image" src={imageError ? defaultImageUrl : apiUrl} alt={formattedString} onError={handleImageError} ></img>
      <p className="item-description">
        <span style={style} className="bold-text">{item.name} - ${item.price}</span>
      </p>
      {showIngredients && (
        <div className="ingredients-window">
          <div className="ingredients-content">
            <p style={style}>Includes:</p>
            {item.ingredients.map((ingredient, index) => (
              <span key={index}>
                {ingredient}{index !== item.ingredients.length - 1 ? ', ' : ''}
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MenuItem;