import React from "react";
import { createChatBotMessage } from "react-chatbot-kit";
import './chatbot.css'

// Define the name of the bot to be used in messages
const botName = "07bBot";

/**
 * Configuration object for the chatbot.
 * This object defines the basic setup, appearance, and initial messages for the chatbot.
 * @author Patralika Ghosh
 */
const config = {
  botName: botName,
  lang: "en", // Language of the chatbot
  customStyles: {
    botMessageBox: {
      backgroundColor: "#376B7E", // Customize the chatbot message box color
    },
    chatButton: {
      backgroundColor: "#5ccc9d", // Customize the chat button color
    },
    
  },
  // Initial messages that the chatbot will present to the user upon activation.
  initialMessages: [
    createChatBotMessage(
      `Hi! I'm ${botName}. I can help answer your questions.` // Initial greeting message from the bot
    ),
    createChatBotMessage(
      "I can answer questions for you that include these topics: \n Accessibility features available on the webapp; \n Drink Recommendations; \n How to log in or sign up?", // Follow-up message
      { delay: 500 } // Delay in milliseconds before showing this message
    )
  ],
  
};

export default config;
