import React, { useEffect, useState } from 'react';
import './modificationsPopup.css';

/**
 * The ItemPopupCustomer component handles the ingredient modifications for the selected item
 * @param {object} selectedItem - Selected item passed in from Item
 * @param {object} index - Index of the selected item in the cart array passed in from Item
 * @param {function} closePopup - closePopup function passed in from Item
 * @param {function} addToCart - addToCart function passed in from ItemPage
 * @author Caleb Elizondo
 * @author Patralika Ghosh
 */
const ItemPopupCustomer = ({ selectedItem, index, closePopup, addToCart }) => {
    // Hooks for state management
    const [allIngredients, setAllIngredients] = useState([]);
    const [defaultIngredients, setDefaultIngredients] = useState([]);
    const [additionalIngredients, setAdditionalIngredients] = useState([]);

    // Effect to fetch the data
    useEffect(() => {
        // Fetch all ingredients
        const fetchData = async () => {
            try {
                let apiUrl;
                if (process.env.NODE_ENV === 'development') {
                    // Development environment
                    apiUrl = 'http://localhost:8000/datamanagement/getIngredientNames';
                } else {
                    // Production environment
                    apiUrl = 'https://csce315331-07b.onrender.com/datamanagement/getIngredientNames';
                }

                const response = await fetch(apiUrl);
                const data = await response.json();
                setAllIngredients(data);
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();
    }, []);

    // Effect to update the ingredients when changed
    useEffect(() => {
        // console.log("Item", selectedItem);
        // console.log("Default Ingredient Names:", selectedItem.ingredients);
        // console.log("All Ingredient Names:", allIngredients);
        // console.log("Additional Ingredient Names:", selectedItem.selectedAdditionalIngredients);

        if (!selectedItem.ingredients || selectedItem.ingredients.length === 0) {
            selectedItem.ingredients = selectedItem.selectedAdditionalIngredients;
        }

        // Extract default ingredients from the selected menu item
        if (selectedItem && selectedItem.ingredients) {
            const defaultIngredientNames = selectedItem.ingredients;

            const defaultIngredientsData = allIngredients.filter(ingredient =>
                defaultIngredientNames.includes(ingredient.id) || defaultIngredientNames.includes(ingredient.name)
            );
            setDefaultIngredients(defaultIngredientsData);

            const additionalIngredientsData = allIngredients.filter(ingredient =>
                !(defaultIngredientNames.includes(ingredient.id) || defaultIngredientNames.includes(ingredient.name))
            );
            setAdditionalIngredients(additionalIngredientsData);
        }
    }, [selectedItem, allIngredients]);

    /**
     * Function to add the modified item to the cart
     */
    const handleAddToOrder = () => {
        let additionalIngredientIDs = additionalIngredients
            .map(ingredient => ingredient.id);

        let modifications = selectedItem.modifications || [];
        let modifications_strings = selectedItem.modifications_strings || [];
        let selectedAdditionalIngredients = [];


    
        // Iterate through default ingredient checkboxes
        defaultIngredients.forEach(ingredient => {
            const checkbox = document.getElementById(`${ingredient.id}`);
            if (checkbox.checked) {
                // Add checked default ingredients to ingredients
                selectedAdditionalIngredients.push(ingredient.id);
            } else {
                // Add unchecked default ingredients as negative ids to modifications
                modifications.push(-ingredient.id);
                modifications_strings.push("RM " + ingredient.name);
            }
        });

        additionalIngredientIDs.forEach(ingredient => {
            const checkbox = document.getElementById(ingredient);
            if (checkbox.checked){
                selectedAdditionalIngredients.push(ingredient);
                modifications.push(ingredient);

                allIngredients.forEach(i => {

                    if (i.id === ingredient){
                        modifications_strings.push("ADD " + i.name);

                    }
                });
                
            }
        })
    
        const modifiedItem = {
            name: selectedItem.name,
            id: selectedItem.id,
            selectedAdditionalIngredients: selectedAdditionalIngredients,
            modifications: modifications,
            price: selectedItem.price,
            modifications_strings: modifications_strings
        };
        addToCart(modifiedItem, index);

        closePopup();
    };
    
    /**
     * Function to update selected ingredient
     * @param {object} ingredientId - selected ingredient
     */
    const handleIngredientToggle = (ingredientId) => {
        // Toggle ingredient selection
        const updatedIngredients = allIngredients.map(ingredient =>
            ingredient.id === ingredientId ? { ...ingredient, checked: !ingredient.checked } : ingredient
        );
        setAllIngredients(updatedIngredients);
    };

    // JSX for the item popup
    return (
        <div className="popup">
            <div className="popup-inner">
                <button className="exit-popup-button" onClick={closePopup}>X</button>
                <h2 className="popup-header">{selectedItem.name}</h2>
                
                <div style={{ display: 'flex', maxHeight: '400px' }}>
                    <div className="default-ingredients">
                        <h3>Remove</h3>
                        {defaultIngredients.map((ingredient) => (
                            <div key={ingredient.id} className="ingredient-item">
                                <label>
                                    <input
                                        id={ingredient.id}
                                        type="checkbox"
                                        defaultChecked
                                        onChange={() => handleIngredientToggle(ingredient.id)}
                                    />
                                    {ingredient.name}
                                </label>
                            </div>
                        ))}
                    </div>

                    <div className="additional-ingredients">
                        <h3>Add</h3>
                        {additionalIngredients.map((ingredient) => (
                            <div key={ingredient.id} className="ingredient-item">
                                <label>
                                    <input
                                        id={ingredient.id}
                                        type="checkbox"
                                        onChange={() => handleIngredientToggle(ingredient.id)}
                                    />
                                    {ingredient.name}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>

                <button className="add-to-cart-button" onClick={handleAddToOrder}>Add to Cart</button>
            </div>
        </div>
    );
};

export default ItemPopupCustomer;
