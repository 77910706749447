import { useState } from "react";
import { Container, Row, Col, Button, Form } from 'react-bootstrap';

/**
 * Component representing a speech assistance interface for the cashier.
 * @returns {JSX.Element} The rendered speech interface component.
 * @author Caleb Elizondo and Nora
 */
const SpeechInterface = () => {

    const [currentCommand, setCurrentCommand] = useState(''); // Acc
    const [customText, setCustomText] = useState('');
    const [visible, toggleVisible] = useState(false); 
    
    /**
     * Handles changing the custom text input.
     * @param {Event} event - The input change event.
     */
    const handleCommandClick = (command) => {
        setCurrentCommand(command);
        speak(command);
    }; 

    const speak = (text) => {
        if (speechSynthesis) {
          // Cancel the current speech synthesis if any
          speechSynthesis.cancel();
      
          // Create a new speech synthesis utterance
          let utterance = new SpeechSynthesisUtterance(text);
      
          // Optionally, you can set properties like voice, pitch, rate, etc.
          // utterance.voice = speechSynthesis.getVoices().filter(voice => voice.lang === 'en-US')[0];
          // utterance.pitch = 1;
          // utterance.rate = 1;
      
          // Speak the text
          speechSynthesis.speak(utterance);
        }
    };
    
    const textCommands = [
        "Hi",
        "How are you?",
        "What would you like to order?",
        "Please type your order and any modifications that you would like, in the text field",
        "Thank you for your visit!",
        "Please wait a moment.",
        "I am going to ask the manager",
        "I am sorry, we do not have that in stock",
        "Of course! My pleasure!",
        "Have a great day!"
    ]; // Acc

    const handleTextInputChange = (event) => {
        setCustomText(event.target.value);
    }; //Acc

    /**
     * Component displaying the current command.
     * @param {Object} props - Props object containing currentCommand.
     * @param {string} props.currentCommand - The current command to display.
     * @returns {JSX.Element} The JSX element representing the displayed command.
     */
    const TextCommandDisplay = ({ currentCommand }) => {
        return (
          <div>
            {currentCommand}
          </div>
        );
    };

    const toggleVisibility = () => {
        toggleVisible(!visible); // Toggle visibility state
    };

    const speakEnteredText = () => {
        speak(customText);
    };


    const scrollableDivStyle = {
        maxHeight: '300px',
        overflowY: 'scroll',
        border: '1px solid #ccc',
        padding: '10px',
    };

    const buttonStyle = {
        backgroundColor: '#f0f0f0', // Neutral color
        marginBottom: '5px',
        color: '#333', // Darker text color
    };

    const toggleButtonStyle = {
        backgroundColor: visible ? '#f00' : '#070', // Example toggle button color change
        color: '#f0f0f0',
        border: 'none',
        padding: '5px 10px',
        margin: '10px',
        cursor: 'pointer',
    };

    const mainWrapperStyle = {
        backgroundColor: '#f5f5f5', // Grey background color
        padding: '20px', // Padding for better visualization
    };

    return (
        <>

            <button onClick={toggleVisibility} style={toggleButtonStyle}>
                {visible ? 'Hide speech assistance' : 'Show speech assistance'}
            </button>
            {visible && (

                <div style={mainWrapperStyle}>
                <Container>
                    <Row className="justify-content-center">
                        <Col xs={12} md={6}>
                            <Form.Control
                                as="textarea"
                                value={customText}
                                onChange={handleTextInputChange}
                                placeholder="Type your message here"
                                style={{ fontSize: '20px', minHeight: '100px' }}
                            />
                            <Button onClick={speakEnteredText} style={{ marginTop: '10px' }}>
                                Speak Text
                            </Button>
                        </Col>
                        <Col xs={12} md={6} style={scrollableDivStyle}>
                            <div className="d-flex flex-column">
                                {textCommands.map((command, index) => (
                                    <Button
                                        key={index}
                                        onClick={() => handleCommandClick(command)}
                                        style={buttonStyle}
                                    >
                                        {command}
                                    </Button>
                                ))}
                            </div>
                        </Col>
                    </Row>
                </Container>
                </div>
            )}
            
        </>
    );
};

export default SpeechInterface;