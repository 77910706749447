import React, { useEffect, useState } from 'react';
import MenuItem from './menuItem.js';
import './menuPage.css';

const MenuCategory = ({ category, fontSize }) => {

  const [menuItems, setMenuItems] = useState([]);

  // Effect to fetch the data
  useEffect(() => {
    const fetchData = async () => {
      try {
        let apiUrl;
        if (process.env.NODE_ENV === 'development') {
          apiUrl = `http://localhost:8000/datamanagement/getMenuItemsByCategory/${category}`;
        } else {
          apiUrl = `https://csce315331-07b.onrender.com/datamanagement/getMenuItemsByCategory/${category}`;
        }

        const response = await fetch(apiUrl);
        const data = await response.json();
        const sortedMenuItems = data.menu_items.sort((a, b) => a.name.localeCompare(b.name));
        setMenuItems(sortedMenuItems);
      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchData();
}, []); // Empty dependency array means this effect runs once after the initial render
  
  return (
    <div className="category-wrapper">
      <div className="category-title">
        <h2 style={{ fontSize: `${fontSize + 15}px` }}>{ category }</h2>
      </div>

      <div className="item-container">
        {menuItems.map(item => (
          <MenuItem key={item.id} category={category} item={item} fontSize={fontSize} />
        ))}
      </div>
    </div>
  );
};

export default MenuCategory;