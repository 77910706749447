import React, { useEffect, useState } from 'react';
import { NavLink} from 'react-router-dom'; // Import useParams
import WeatherWidget from '../weatherWidget';
import ItemPopupCustomer from './ItemPopupCustomer';  // Import the ItemPopupCustomer component
import './cart.css';
import { googleLogout } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';

/**
 * The Cart component handles all of the cart and checkout aspects
 * Like updating or removing cart items and checking out the order when finished
 * @author Maria Visinescu
 * @author Patralika Ghosh
 */
const Cart = () => {
  // Hooks for state management
  const [cartItems, setCartItems] = useState([]); // State to store cart items
  const [totalPrice, setTotalPrice] = useState(0); // State to store total price
  const [showPopup, setShowPopup] = useState(false); // State to manage modifications pop-up
  const [selectedItem, setSelectedCartItem] = useState(null);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  let currentCart = JSON.parse(sessionStorage.getItem('cart')) || [];
  let currentCartID = JSON.parse(sessionStorage.getItem('cartID')) || [];
  const [isLoggedIn, setLoggedIn] = useState(localStorage.getItem('isLoggedIn'));
  const [isLoggedInGoogle, setLoggedInGoogle] = useState(false);
  const navigate = useNavigate();

  
  
  // Effect to fetch the data
  useEffect(() => {
    // Initialize the cart from local storage (if available)
    const newCart = JSON.parse(sessionStorage.getItem('cart')) || [];
    const newCartID = JSON.parse(sessionStorage.getItem('cartID')) || [];

    // Check if the values have changed before updating the state
    if (!arraysEqual(newCartID, cartItems)) {
      setCartItems(newCartID);
    }

    const newTotalPrice = calculateTotalPrice(newCartID);
    if (newTotalPrice !== totalPrice) {
      setTotalPrice(newTotalPrice);
    }

    
  }, [cartItems, totalPrice]);

  /**
   * Function to check if two arrays are the same
   * Used to check whether the cart has changed
   * @param {object} a - first array
   * @param {object} b - second array
   */
  function arraysEqual(a, b) {
    return JSON.stringify(a) === JSON.stringify(b);
  }

  /**
   * Function to calculate the total price of the items in the cart
   * @param {object} items - array of the cart items
   */
  function calculateTotalPrice(items) {
    return items.reduce((total, item) => total + parseFloat(item.price), 0);
  }

  /**
   * Function to send the order to the database and complete the checkout process
   */
  async function sendCartOrder() {
    try {
      // Fetch CSRF token
      // const csrfResponse = await fetch('http://localhost:8000/datamanagement/getcsrftoken/');
      // const csrfData = await csrfResponse.json();
      // const csrfToken = csrfData.csrf_token;
  
      // Fetch order
      const currentCartID = JSON.parse(sessionStorage.getItem('cartID')) || [];
      const currentCart = JSON.parse(sessionStorage.getItem('cart')) || [];
      // console.log('Current Cart ID:', currentCartID);
      // console.log('Current Cart:', currentCart);

      if (currentCartID.length === 0) {
        alert('Cart is empty, order cannot be placed.');
        return;
      }
      
      let apiUrl;
      if (process.env.NODE_ENV === 'development') {
        // Development environment
        apiUrl = `http://localhost:8000/datamanagement/sendCartOrder/`;
      } else {
        // Production environment
        apiUrl = `https://csce315331-07b.onrender.com/datamanagement/sendCartOrder/`;
      }

      const orderResponse = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ currentOrder: currentCartID }),
      });
  
      if (orderResponse.ok) {
        console.log('Order sent to Django view');
        // Once the order is successfully sent, reset the currentOrder
        sessionStorage.removeItem('cart');
        sessionStorage.removeItem('cartID');
        // Update the session storage to reflect the empty cart
        sessionStorage.setItem('cart', JSON.stringify([]));
        sessionStorage.setItem('cartID', JSON.stringify([]));
        // Update the state to reflect the empty cart
        setCartItems([]);
        setTotalPrice(0);
        // Display a message to confirm that the order was placed
        alert(`Order has been placed. Have a great day!`);
      } else {
        console.error('Failed to send order to Django view');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  /**
   * Function to remove the selected item from the cart
   * @param {object} modifiedItem - modified item
   * @param {object} index - location of the selected item in the cart
   */
  const removeFromCart = (modifiedItem, index) => {
    // Filter out the item with the specified menuItemId from the cart
    const updatedCartID = currentCartID.filter((_, i) => i !== index);
    const updatedCart = currentCart.filter((_, i) => i !== index);

    // Update session storage
    sessionStorage.setItem('cartID', JSON.stringify(updatedCartID));
    sessionStorage.setItem('cart', JSON.stringify(updatedCart));

    // Update the cartItems state and total price
    setCartItems(updatedCart);
    setTotalPrice(calculateTotalPrice(updatedCart));

    // Display a message to confirm the item was added to the cart
    alert(`Removed "${modifiedItem.name}" from the cart.`);
  };

  /**
   * Function to add the modified item to the cart and delete the previous version of that item
   * @param {object} modifiedItem - modified item
   * @param {object} index - location of the selected item in the cart
   */
  const handleAddToCart = (modifiedItem, index) => {
    // Delete the old item from the cart
    const updatedCartID = currentCartID.filter((_, i) => i !== index);
    const updatedCart = currentCart.filter((_, i) => i !== index);

    // Add the modified item to the cart
    updatedCartID.push(modifiedItem);
    updatedCart.push(modifiedItem.name);

    // Update session storage
    sessionStorage.setItem('cartID', JSON.stringify(updatedCartID));
    sessionStorage.setItem('cart', JSON.stringify(updatedCart));

    // Update the cartItems state and total price
    setCartItems(updatedCart);
    setTotalPrice(calculateTotalPrice(updatedCart));

    // Display a message to confirm the modifications
    alert(`Modifications applied to "${modifiedItem.name}".`);

    // Close the modifications pop-up
    setShowPopup(false);
  }; 

  /**
   * Function to update the ingredients for the selected item
   * Opens the modifications pop up
   * @param {object} index - location of the selected item in the cart
   */
  const updateCart = (index) => {
    // Find the selected item in the cart
    const selectedItem = cartItems[index];
    setSelectedItemIndex(index);

    // console.log('Cart Items:', cartItems);
    // console.log('Selected Item:', selectedItem);

    // Open the modifications pop-up with the selected item
    setSelectedCartItem(selectedItem);
    setShowPopup(true);
  };

  /**
   * Function to log out the customer using oauth
   */
  const handleGoogleSignOut = () => {
    googleLogout();
    localStorage.removeItem('isLoggedInGoogle');
    setLoggedInGoogle(false);
    navigate('/');
    alert("You have been logged out.");
  };

  /**
   * Function that handles user logout.
   * Resets state and optionally navigates to the login page.
   */
  const handleLogout = () => {
    setLoggedIn(false);
    // Optionally, you can navigate to the login page after logout
    navigate('/');
    localStorage.removeItem('isLoggedIn');
    alert("You have been logged out.");
  };

  // JSX for the cart
  return (
    <>
      <div className="top-bar">
        <div>
          <NavLink to="/" style={({ isActive }) => ({ color: isActive ? 'pink' : 'white' })}>
            Home
          </NavLink>
        </div>
        <div id="google_translate_element">Google Translate</div> 
        <div>
          {isLoggedIn ? (
            <button className="logout-button" onClick={handleLogout}>
              Logout
            </button>
          ) : (
            <button className="logout-button" onClick={handleGoogleSignOut}>
              Logout
            </button>
          )}
        </div>
      </div>

      {/* Include the WeatherWidget component */}
      <WeatherWidget />

      <h2 id="cartHeader" style={{ fontSize: '36px', textAlign: 'center', marginTop: '20px' }}>Cart Contents</h2>
      
      <div id="cartItems" style={{ fontSize: '22px', textAlign: 'center' }}>
        {cartItems.map((item, index) => (
          <div key={index}>
            <div className="cart-item">
              <p>{item.name} - ${item.price}</p>
            </div>
            <p className="ingredients" style={{ fontSize: '15px' }}>
              {item.modifications_strings && item.modifications_strings.map((modification, modIndex) => (
                <React.Fragment key={modIndex}>
                  {modification}
                  <br />
                </React.Fragment>
              ))}
            </p>
            <button className="delete-item-button" onClick={() => removeFromCart(item, index)}>Delete Item</button>
            <button className="update-cart-button" onClick={() => updateCart(index)}>Update Item</button>
          </div>
        ))}
      </div>
      
      <div id="totalPriceContainer" style={{ textAlign: 'center' }}>
        Total Price: ${totalPrice.toFixed(2)}
      </div>

      <button className="checkout-button" onClick={sendCartOrder}>
        Checkout
      </button>
    
      <NavLink to="/customer">
        <button className="back">
          Back
        </button>
      </NavLink>

      {/* Modifications Pop-up */}
      {showPopup && selectedItem && (
        <ItemPopupCustomer
          selectedItem={selectedItem}
          index={selectedItemIndex}
          closePopup={() => setShowPopup(false)}
          addToCart={handleAddToCart}
        />
      )}
    </>
  );
};

export default Cart;