import React, { useState, useEffect } from 'react';
import { Table, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './managerPages.css';

const ManagerPopularityReport = () => {
  const [popularity, setPopularity] = useState([]);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [validationError, setValidationError] = useState('');

  const fetchData = async () => {
    try {
      let apiUrl;
      if (process.env.NODE_ENV === 'development') {
        apiUrl = `http://localhost:8000/datamanagement/managerPopularityReport/${startTime}/${endTime}`;
      } else {
        apiUrl = `https://csce315331-07b.onrender.com/datamanagement/managerPopularityReport/${startTime}/${endTime}`;
      }

      const response = await fetch(apiUrl);
      const data = await response.json();
      setPopularity(data.popularity);  // Update to match the new format of popularity data
    } catch (error) {
      console.error(`Error fetching popularity data: ${error.message}`);
    }
  };

  const handleLoadReportClick = async () => {
    // Validate the start time and end time formats
    if (!isValidDateFormat(startTime) || !isValidDateFormat(endTime)) {
      setValidationError('Invalid date format. Please use YYYY-MM-DD.');
      return;
    }
    // Fetch data when the "Load Report" button is clicked
    await fetchData();

    setValidationError('');

    // Log the selected time range
    console.log(`Start Time: ${startTime}, End Time: ${endTime}`);
  };

  const isValidDateFormat = (dateString) => {
    const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
    return dateFormatRegex.test(dateString);
  };

  return (
    <div>
      <label htmlFor="startTime">Start Time:</label>
      <input
        type="text"
        id="startTime"
        value={startTime}
        onChange={(e) => setStartTime(e.target.value)}
        placeholder="YYYY-MM-DD"
      />

      <label htmlFor="endTime">End Time:</label>
      <input
        type="text"
        id="endTime"
        value={endTime}
        onChange={(e) => setEndTime(e.target.value)}
        placeholder="YYYY-MM-DD"
      />

      <Button variant="primary" onClick={handleLoadReportClick}>
        Load Report
      </Button>

      <div className="scrollable-tbody">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Menu Item ID</th>
              <th>Menu Item Name</th>
              <th>Popularity Count</th>
            </tr>
          </thead>
          <tbody className="scrollable-tbody">
            {popularity.map((menuItemPopularity) => (
              <tr key={menuItemPopularity.menu_item_id}>
                <td>{menuItemPopularity.menu_item_id}</td>
                <td>{menuItemPopularity.menu_item_name}</td>
                <td>{menuItemPopularity.popularity_count}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default ManagerPopularityReport;
