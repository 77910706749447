import React from 'react';
import "./currentOrderDisplay.css";

/**
 * Component displaying the current order to the user.
 * @param {Object} props - Props object containing cartItems, removeItem, and editItem functions.
 * @param {Object[]} props.cartItems - Array of items in the current order.
 * @param {Function} props.removeItem - Function to remove an item from the order.
 * @param {Function} props.editItem - Function to edit an item in the order.
 * @param {number} props.fontSize - Font size value.
 * @returns {JSX.Element} The rendered current order display component.
 * @author Caleb Elizondo
 */
const CurrentOrderDisplay = ({ cartItems, removeItem, editItem, fontSize }) => {
    const totalPrice = cartItems.reduce((acc, item) => acc + parseFloat(item.price), 0);

    /**
     * Handles removing an item from the order.
     * @param {number} itemId - ID of the item to be removed.
     */
    const handleRemoveItem = (itemId) => {
        removeItem(itemId);
    };

    /**
     * Handles editing an item in the order.
     * @param {number} itemId - ID of the item to be edited.
     */
    const handleEditItem = (itemId) => {
        editItem(itemId);
    };

    /**
     * Renders the current order display component.
     * @returns {JSX.Element} The JSX element representing the current order display.
     */
    return (
        <>
            <div className="banner bg-secondary">
                <h2 className="text-center text-light p-3" style={{ fontSize: `${fontSize + 15}px` }}>
                    Current Order: ${totalPrice.toFixed(2)}
                </h2>
            </div>
            <div className="current-order-display" style={{ fontSize: `${fontSize}px` }}>
                <div className="order-items">
                    <ul className="list-group">
                        {cartItems.map((item, index) => (
                            <li key={index} className="list-group-item" style={{ fontSize: `${fontSize - 4}px` }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {/* Add margins to buttons and space between elements */}
                                    <button
                                        className="btn btn-sm btn-outline-secondary mr-2"
                                        onClick={() => handleRemoveItem(item.itemId)}
                                        style={{ fontSize: `${fontSize}px` }}
                                    >
                                        x
                                    </button>
                                    <button
                                        className="btn btn-sm btn-outline-secondary mr-2"
                                        onClick={() => handleEditItem(item.itemId)}
                                        style={{ fontSize: `${fontSize}px` }}
                                    >
                                        edit
                                    </button>
                                    <strong style={{ fontSize: `${fontSize}px`, marginRight: '10px' }}>
                                        {item.name}
                                    </strong> - ${item.price}
                                </div>
                                {item.modifications_strings && item.modifications_strings.map((modification, idx) => (
                                    <div key={idx} className="pl-3" style={{ fontSize: `${fontSize}px` }}>
                                        <em>{modification}</em>
                                    </div>
                                ))}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    );
};

export default CurrentOrderDisplay;