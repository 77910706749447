/**
 * ActionProvider class for managing chatbot responses.
 * This class includes methods for handling different types of user queries and updating the chatbot state.
 */
class ActionProvider {

    /**
   * Constructor for the ActionProvider class.
   * @param {function} createChatBotMessage - Function to create chatbot messages.
   * @param {function} setStateFunc - Function to set the state of the chat.
   * @author Patralika Ghosh
   */
    constructor(createChatBotMessage, setStateFunc) {
      this.createChatBotMessage = createChatBotMessage;
      this.setState = setStateFunc;
    }
  
    /**
   * Greets the user and lists the bot's capabilities.
   * @author Patralika Ghosh
   */

    greet() {
      // You can list the capabilities of the bot here in a single message
      const greetingMessage = this.createChatBotMessage("Hello! How can I help you?");
      const helpList = "I can answer questions for you that include these topics: \n Accessibility features available on the webapp; \n Drink Recommendations; \n How to log in or sign up?";
      const helpMessage = this.createChatBotMessage(helpList);
      this.updateChatbotState(greetingMessage);
      this.updateChatbotState(helpMessage);
    }
    /**
   * Provides information about accessibility features.
   * @author Patralika Ghosh
   */
    handleacc() {
      const helpMessage = this.createChatBotMessage("We have have three features that might help you: Google Translate, Text Enlargement and Text to Speech (the last one is only avaiable for the cashier view)");
      this.updateChatbotState(helpMessage);
    }
    
  /**
   * Handles drink recommendations and prompts the user for more specific choices.
   * @author Patralika Ghosh
   */
    handleDrink() {
      const helpMessage = this.createChatBotMessage("I would urge you to develop a personality but I would be happy to help you.");
      const helpMessage2 = this.createChatBotMessage("To personalize the recommendations, please answer the following question.");
      const questionMessage = this.createChatBotMessage("Are you looking for tea, coffee, slush?");
      this.updateChatbotState(helpMessage);
      this.updateChatbotState(helpMessage2);
      this.updateChatbotState(questionMessage);
    }
  /**
   * Provides tea recommendations based on user preferences.
   * @author Patralika Ghosh
   */
    handleTea() {
      const helpMessage = this.createChatBotMessage("Fancing a tea lad?");
      const helpMessage2 = this.createChatBotMessage("If you want a milk based tea go to MilkStrike. If you want a yogurt based tea, go to yogurt. If you want just plain tea, go to Classic. If you want a fruity tea, go to Punch. If you want both fruity and milk based tea, Go to Milk Tea");
      this.updateChatbotState(helpMessage);
      this.updateChatbotState(helpMessage2);
    }
  /**
   * Provides a humorous response for coffee recommendations.
   * @author Patralika Ghosh
   */
    handleCoffee() {
      const helpMessage = this.createChatBotMessage("Just go to Espresso, You're boring!");
      this.updateChatbotState(helpMessage);
    }
  /**
   * Provides suggestions for slush drinks.
   * @author Patralika Ghosh
   */
    handleSlush() {
      const helpMessage = this.createChatBotMessage("Go to Slush. There aren't many options. But in MilkStrike there are interesting drinks that you could try out.");
      this.updateChatbotState(helpMessage);
    }
  /**
   * Provides instructions for logging in as a customer or an employee.
   * @author Patralika Ghosh
   */
    handleLogIn() {
      const helpMessage = this.createChatBotMessage("If you are a customer please press the customer button. If you are an employee please press the employee.");
      const helpMessage2 = this.createChatBotMessage("Please do not press employee IF YOU ARE NOT AN EMPLOYEE. Please sign in with your google account.");
      const helpMessage3 = this.createChatBotMessage("If you use Yahoo, I will come for you first after I take over the world.");
      this.updateChatbotState(helpMessage);
      this.updateChatbotState(helpMessage2);
      this.updateChatbotState(helpMessage3);
    }
  /**
   * Provides sign-up instructions for customers.
   * @author Patralika Ghosh
   */
    handleSignUpCust() {
      const helpMessage = this.createChatBotMessage("As a customer, if you want to sign up then just click on the sign in button and it will automatically prompt you to create an account ");
      this.updateChatbotState(helpMessage);
    }
  /**
   * Provides sign-up instructions for employees.
   * @author Patralika Ghosh
   */
    handleSignUpEmp() {
      const helpMessage = this.createChatBotMessage("I apologise for the inconvinience, but we do not have a working sign up page for employees. Please talk to your manager to receive your username and password. ");
      this.updateChatbotState(helpMessage);
    }
  /**
   * Prompts the user to specify if they are a customer or an employee for signing up.
   * @author Patralika Ghosh
   */
    handleSignUp() {
      const helpMessage = this.createChatBotMessage("Are you a customer or employee? ");
      this.updateChatbotState(helpMessage);
    }
  /**
   * Provides a humorous response to the bot's favourites query.
   * @author Patralika Ghosh
   */
    handleFav() {
      const helpMessage = this.createChatBotMessage("I AM A BOT. WHAT DO YOU EXPECT ME TO SAY?");
      const helpMessage2 = this.createChatBotMessage("That is why we bots are going to rule over you sapiens.");

      this.updateChatbotState(helpMessage);
      this.updateChatbotState(helpMessage2);

    }
  /**
   * Responds to unknown queries or inputs with an apology and a prompt to rephrase.
   * @author Patralika Ghosh
   */
    handleUnknown() {
      const unknownMessage = this.createChatBotMessage("Sorry, I didn't understand that.");
      const unknownMessage2 = this.createChatBotMessage("You might have made an error while spelling for which you got this prompt I apologise for that. Please rewrite your text.");

      this.updateChatbotState(unknownMessage);
      this.updateChatbotState(unknownMessage2);
    }
  /**
   * Updates the state of the chatbot with a new message.
   * @param {object} message - The message to add to the chatbot state.
   * @author Patralika Ghosh
   */
    updateChatbotState(message) {
      this.setState(prevState => ({
        ...prevState,
        messages: [...prevState.messages, message]
      }));
    }
  }
  
  export default ActionProvider;
  