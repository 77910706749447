import React, { useEffect, useState } from 'react';
import CashierMenuItem from './cashierMenuItem';
import ItemPopup from './ItemPopup';
import './cashierCategoryDisplay.css';


/**
 * Component displaying menu items for a specific category in the cashier interface.
 * @param {Object} props - Props object containing category and addToCart function.
 * @param {string} props.category - The category to display menu items for.
 * @param {Function} props.addToCart - Function to add an item to the cart.
 * @returns {JSX.Element} The rendered cashier category display component.
 * @author Caleb Elizondo
 */
const CashierCategoryDisplay = ({ category, addToCart, fontSize }) => {
  const [menuItems, setMenuItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  // Fetch menu items by category
  useEffect(() => {
    const fetchData = async () => {
      try {
        let apiUrl;
        if (process.env.NODE_ENV === 'development') {
          apiUrl = `http://localhost:8000/datamanagement/getMenuItemsByCategory/${category}`;
        } else {
          apiUrl = `https://csce315331-07b.onrender.com/datamanagement/getMenuItemsByCategory/${category}`;
        }

        const response = await fetch(apiUrl);
        const data = await response.json();

        // Sort menu items alphabetically by name
        const sortedMenuItems = data.menu_items.sort((a, b) => a.name.localeCompare(b.name));

        setMenuItems(sortedMenuItems);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    if (category) {
      fetchData();
    }
  }, [category]);


  /**
   * Displays the item popup when an item is selected.
   * @param {Object} item - The selected item.
   */
  const showPopup = (item) => {
    setSelectedItem(item);
  };


  /**
   * Closes the item popup.
   */
  const closePopup = () => {
    setSelectedItem(null);
  };


  /**
   * Renders the cashier category display component.
   * @returns {JSX.Element} The JSX element representing the category display.
   */
  return (
    <>

      {selectedItem && (
        <ItemPopup selectedItem={selectedItem} closePopup={closePopup} addItemToOrder={addToCart} />
      )}
      <div className="container-fluid">
        <div className="row">
          {menuItems.map((item) => (
            <div key={item.id} className="col-6 col-md-4 col-lg-3 mb-3">
              <CashierMenuItem showPopup={showPopup} item={item} category={category} fontSize={fontSize + 10} />
            </div>
          ))}
        </div>
      </div>


    </>
  );
};

export default CashierCategoryDisplay;
