import React, { useEffect, useState } from 'react';
import { NavLink, useLocation} from 'react-router-dom';
import MenuCategory from './menuCategory';
import WeatherWidget from '../weatherWidget';
import Dashboard from './dashboard';
import './menuPage.css';

/**
 * The MenuPage component displays all of the menu items and associated characteristics
 */
const MenuPage = () => {
  // Hooks for state management
  const [menuCategories, setMenuCategories] = useState([]);
  const location = useLocation();
  const [isLoggedInGoogle, setLoggedInGoogle] = useState(localStorage.getItem('isLoggedInGoogle'));
  const [isLoggedIn, setLoggedIn] = useState(localStorage.getItem('isLoggedIn'));
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false
      },
      "google_translate_element"
    );
  };

  // Effect to fetch the data
  useEffect(() => {
    const fetchData = async () => {
      try {
        let apiUrl;
        if (process.env.NODE_ENV === 'development') {
          // Development environment
          apiUrl = 'http://localhost:8000/datamanagement/getCategories';
        } else {
          // Production environment
          // Use the actual production API URL here
          apiUrl = 'https://csce315331-07b.onrender.com/datamanagement/getCategories';
        }

        const response = await fetch(apiUrl);
        const data = await response.json();
        setMenuCategories(data.menu_categories);
        console.log(data.menu_categories);
      } catch (error) {
        console.error('Error:', error);
      }
    };
    
    fetchData();
  }, []); // Empty dependency array means this effect runs once after the initial render


  const [fontSize, setFontSize] = useState(16); 


  const increaseFontSize = () => {
    setFontSize(prevSize => prevSize + 2); // Increase font size by 2 pixels
  };

  // Function to decrease font size
  const decreaseFontSize = () => {
    setFontSize(prevSize => Math.max(prevSize - 2, 12)); // Decrease font size by 2 pixels, with a minimum size of 12px
  };

  document.body.style.fontSize = `${fontSize}px`;

  const style = {
    fontSize: {fontSize}
  };


  // JSX for the menu board
  return (
    <>
      <div className="top-bar">
        <div>
          <NavLink to="/" style={({ isActive }) => ({ color: isActive ? 'pink' : 'white' })}>
            <img alt="hi" src="https://www.kungfutea.com/wp-content/uploads/2021/08/CopyofSquircleLogo_smaller-600x544.png" height={100}px />
          </NavLink>
        </div>
        <div id="google_translate_element">Google Translate</div> 
        <div>
          {isLoggedIn ? (
            <NavLink to="/login" style={({ isActive }) => ({ color: isActive ? 'pink' : 'white' })}>
              Employee View
            </NavLink>
          ) : isLoggedInGoogle ? (
            <NavLink to="/login" style={({ isActive }) => ({ color: isActive ? 'pink' : 'white' })}>
              Customer View
            </NavLink>
          ) : (
            <NavLink to="/login" style={({ isActive }) => ({ color: isActive ? 'pink' : 'white' })}>
              Log In
            </NavLink>
          )}
        </div>
      </div>

      {/* Include the WeatherWidget component */}
      <WeatherWidget />
    

      <div className="menu-background">
      <h1 className="menu-header" style={{ fontSize: `${fontSize + 25}px` }}>Menu</h1>
        <div className="menu-categories">
          {menuCategories.map(category => (
            <MenuCategory category={category} key={category.id} fontSize={fontSize}/>
          ))}
        </div>
      </div>


    <Dashboard fontSize={fontSize} setFontSize={setFontSize}/>

    </>
  );
};

export default MenuPage;
