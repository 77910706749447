import React, { useState, useEffect } from 'react';
import { Table, Button, Form, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './managerPages.css';


/**
 * ManagerOrderTable class displays the order table in the manager view
 * It handles updating the order table and fetching the relevant data from the django backend
 * @param {object|null} orders - The Order objects retrieved from the Django backend
 * @author Sophia Evanisko
 */
const ManagerOrderTable = ({ orders }) => {
  const [rows, setRows] = useState(orders);

  /**
     * Will delete the entry with the given id from the table and update the display
     * @param {*} id - the id of the entry to be deleted
     * @param {*} table - a string representing the purpose of the table i.e. "order"
    */
  const deleteRow = async (id, table) => {
    console.log("Attempting to delete " + id);

    try {
      let apiUrl;
      if (process.env.NODE_ENV === 'development') {
        apiUrl = `http://localhost:8000/datamanagement/deleteRow/${id}/${table}`;
      } else {
        apiUrl = `https://csce315331-07b.onrender.com/datamanagement/deleteRow/${id}/${table}`;
      }

      // Make a DELETE request to your Django backend to delete the order
      await fetch(apiUrl);

      // Update the local state by removing the deleted order
      const updatedRows = rows.filter((order) => order.id !== id);
      setRows(updatedRows);
    } catch (error) {
      console.error(`Error deleting order: ${error.message}`);
    }
  };

  
  /**
   * Update the local state when the orders prop changes
   */
  useEffect(() => {
    setRows(orders);
  }, [orders]);

 
  return (
    <div>
        <div className="scrollable-tbody">
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Date</th>
                        <th>Items</th>
                        <th>Modifications</th>
                        <th>Price</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((order) => (
                        <tr key={order.id}>
                        <td>{order.id}</td>
                        <td>{order.purchase_datetime}</td>
                        <td>{order.menu_items}</td>
                        <td>{order.modifications.join(', ')}</td>
                        <td>{order.price}</td>
                        <td>
                            <Button
                            variant="danger"
                            onClick={() => deleteRow(order.id, "customerorder")}
                            >
                            Delete
                            </Button>
                        </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    </div>
    
  );
};

export default ManagerOrderTable;
