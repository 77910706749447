import React, { useState, useEffect } from 'react';
import { Table, Button, Form, Row, FormCheck } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './managerPages.css';

/**
 * ManagerInventoryTable class displays the inventory table in the manager view
 * It handles adding, editing, deleting and updating the inventory table and fetching the relevant data from the django backend
 * @param {object|null} inventory - The Ingredient objects retrieved from the Django backend
 * @author Sophia Evanisko
 */
const ManagerInventoryTable = ({ inventory }) => {

    const [rows, setRows] = useState(inventory);
    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [newIngredient, setNewIngredient] = useState({ name: '', ingtype: 'Quantity', amount: '', stan_amount:'', id: '' });
    const [update, setUpdate] = useState(false);

    const refreshTable = async () => { 
        try {
            let apiUrl;
            if (process.env.NODE_ENV === 'development') {
                apiUrl = `http://localhost:8000/datamanagement/getInventory`;
            } else {
                apiUrl = `https://csce315331-07b.onrender.com/datamanagement/getInventory`;
            }

            const response = await fetch(apiUrl);
            const data = await response.json();
            console.log(data);
            inventory = data.inventory;
            console.log(inventory)
            setRows(data.inventory);

        } catch (error) {
            console.error('Error:', error);
        }
    };


    /**
     * Will delete the entry with the given id from the table and update the display
     * @param {*} id - the id of the entry to be deleted
     * @param {*} table - a string representing the purpose of the table i.e. "inventory"
     */
    const deleteRow = async (id, table) => {
        console.log("Attempting to delete " + id);
    
        try {
            let apiUrl;
            if (process.env.NODE_ENV === 'development') {
                apiUrl = `http://localhost:8000/datamanagement/deleteRow/${id}/${table}`;
              } else {
                apiUrl = `https://csce315331-07b.onrender.com/datamanagement/deleteRow/${id}/${table}`;
            }
    
            await fetch(apiUrl);

            const updatedRows = rows.filter((row) => row.id !== id);
            setRows(updatedRows);
        } catch (error) {
            console.error(`Error deleting ingredient: ${error.message}`);
        }
    };


    /**
     * Shows the add ingredient form and hides the edit ingredient form
     */
    const handleAddIngredient = () => {
        setShowAddForm(true);
        setShowEditForm(false);
        setUpdate(!update);
    };


    /**
     * Hides the add ingredient form and displays the edit ingredient form
     */
    const handleEditIngredient = () => {
        setShowAddForm(false);
        setShowEditForm(true);
    }


    const handleAddFormSubmit = async (event) => {
        event.preventDefault();
        // Access the name value from newEmployee state
        const { name, ingtype, amount, stan_amount } = newIngredient;
        console.log(ingtype);

        try {
            let apiUrl;
            if (process.env.NODE_ENV === 'development') {
                apiUrl = `http://localhost:8000/datamanagement/addIngredient/`;
            } else {
                apiUrl = `https://csce315331-07b.onrender.com/datamanagement/addIngredient/`;
            }

            // Make a post request to Django backend to add the employee
            const response = await fetch(apiUrl, {
                method: 'POST',
                body: JSON.stringify({ name: name, type: ingtype, amount: amount, stan_amount: stan_amount}),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = await response.json();

            // Reset the form and hide it
            setNewIngredient({ name: '', ingtype: 'Quantity', amount: '', stan_amount:'', id: '' });
            setShowAddForm(false);

            // After successfully adding the employee, update the local state
            refreshTable();

        } catch (error) {
            console.error(`Error adding ingredient: ${error.message}`);
        }

    };


    const handleEditFormSubmit = async (event) => {
        event.preventDefault();
        // Access the name, unionized, and id values from newEmployee state
        const { name, ingtype, amount, stan_amount, id } = newIngredient;
        console.log(ingtype);

        try {
            let apiUrl;
            if (process.env.NODE_ENV === 'development') {
                apiUrl = `http://localhost:8000/datamanagement/editIngredient/`;
            } else {
                apiUrl = `https://csce315331-07b.onrender.com/datamanagement/editIngredient/`;
            }

            // Make a POST request to your Django backend to edit the employee
            const response = await fetch(apiUrl, {
                method: 'POST',
                body: JSON.stringify({ name: name, type: ingtype, amount: amount, stan_amount: stan_amount, id:id}),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            await response.json();

            // Reset the form and hide it
            setNewIngredient({ name: '', ingtype: 'Quantity', amount: '', stan_amount:'', id: '' });
            setShowEditForm(false);

            refreshTable();

        } catch (error) {
            console.error(`Error editing employee: ${error.message}`);
        }
    };


    /**
     * Update the local state when the inventory prop changes
     */
    useEffect(() => {
        setRows(inventory);
        console.log("update");
      }, [inventory]);


      useEffect(() => {
        console.log("out");
      }, [rows]);


    return (
        <div>
            <div>
                <Button variant="info" id="button-basic" onClick={() => handleAddIngredient()}>Add Ingredient</Button>
                <Button variant="warning" id="button-basic" onClick={() => handleEditIngredient()}>Edit Ingredient</Button>
            </div>

            <div>
                {showAddForm && (
                    <div> 
                        <Form>
                            <Row>
                                <Form.Group style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Form.Control
                                        className="input-field"
                                        type="text"
                                        placeholder="Name"
                                        name="name"
                                        value={newIngredient.name}
                                        onChange={(e) => setNewIngredient({ ...newIngredient, name: e.target.value })}
                                    />
                                    <FormCheck
                                        className="input-field"
                                        type="switch"
                                        label={newIngredient.ingtype === "Weight" ? "Weight" : "Quantity"}
                                        checked={newIngredient.ingtype === "Weight"}
                                        onChange={(e) => setNewIngredient({ ...newIngredient, ingtype: e.target.checked ? "Weight" : "Quantity" })}
                                    />
                                    <Form.Control
                                        className="input-field"
                                        type="number"
                                        placeholder="Amount"
                                        value={newIngredient.amount}
                                        onChange={(e) => setNewIngredient({ ...newIngredient, amount: e.target.value })}
                                    />
                                    <Form.Control
                                        className="input-field"
                                        type="number"
                                        placeholder="Standard Amount"
                                        value={newIngredient.stan_amount}
                                        onChange={(e) => setNewIngredient({ ...newIngredient, stan_amount: e.target.value })}
                                    />
                                    <Button
                                        className="submit-button input-field"
                                        value="submit"
                                        type="submit"
                                        onClick={(e) => handleAddFormSubmit(e)}
                                    >
                                        Submit
                                    </Button>
                                </Form.Group>
                            </Row>
                            
                        </Form>
                    </div>

                )}
            </div>

            <div>
                {showEditForm && (
                    <div> 
                        <Form>
                            <Row>
                                <Form.Group style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Form.Control
                                        className="input-field"
                                        type="text"
                                        placeholder="ID"
                                        name="id"
                                        value={newIngredient.id}
                                        onChange={(e) => setNewIngredient({ ...newIngredient, id: e.target.value })}
                                    />
                                    <Form.Control
                                        className="input-field"
                                        type="text"
                                        placeholder="Name"
                                        name="name"
                                        value={newIngredient.name}
                                        onChange={(e) => setNewIngredient({ ...newIngredient, name: e.target.value })}
                                    />
                                    <FormCheck
                                        className="input-field"
                                        type="switch"
                                        label={newIngredient.ingtype === "Weight" ? "Weight" : "Quantity"}
                                        checked={newIngredient.ingtype === "Weight"}
                                        onChange={(e) => setNewIngredient({ ...newIngredient, ingtype: e.target.checked ? "Weight" : "Quantity" })}
                                    />
                                    <Form.Control
                                        className="input-field"
                                        type="number"
                                        placeholder="Amount"
                                        value={newIngredient.amount}
                                        onChange={(e) => setNewIngredient({ ...newIngredient, amount: e.target.value })}
                                    />
                                    <Form.Control
                                        className="input-field"
                                        type="number"
                                        placeholder="Standard Amount"
                                        value={newIngredient.stan_amount}
                                        onChange={(e) => setNewIngredient({ ...newIngredient, stan_amount: e.target.value })}
                                    />
                                    <Button
                                        className="submit-button input-field"
                                        value="submit"
                                        type="submit"
                                        onClick={(e) => handleEditFormSubmit(e)}
                                    >
                                        Submit
                                    </Button>
                                </Form.Group>
                            </Row>
                            
                        </Form>
                    </div>

                )}
            </div>

            <div className="scrollable-tbody">
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Weight</th>
                            <th>Quantity</th>
                            <th>Standard Amount</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((ingredient) => (
                            <tr key={ingredient.id}>
                                <td>{ingredient.id}</td>
                                <td>{ingredient.name}</td>
                                <td>{ingredient.type}</td>
                                <td>{ingredient.weight}</td>
                                <td>{ingredient.quantity}</td>
                                <td>{ingredient.standard_amount}</td>
                                <td>
                                    <Button variant="danger" onClick={() => deleteRow(ingredient.id, "ingredient")}>
                                    Delete
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
        
        
    );
};
  
export default ManagerInventoryTable;