import SpeechInterface from "./speechInterface";
import React, { useState } from 'react';
import "./sendorder.css"


/**
 * Component representing the button to send the order in the cashier interface.
 * @param {Object} props - Props object containing sendOrder function.
 * @param {Function} props.sendOrder - Function to send the order.
 * @returns {JSX.Element} The rendered send order button component.
 * @author Caleb Elizondo
 */
const SendOrderButton = ({ sendOrder, fontSize, increaseFontSize, decreaseFontSize }) => {
        const [isSending, setIsSending] = useState(false);


        /**
         * Handles sending the order.
         */
        const handleSendOrder = async () => {
            setIsSending(true);
    
            try {
                await sendOrder();
            } catch (error) {
                console.error('Error while sending order:', error);
            } finally {
                setTimeout(() => {
                    setIsSending(false);
                }, 1500); 
            }
        };

        /**
         * Renders the send order button component.
         * @returns {JSX.Element} The JSX element representing the send order button.
         */
        return (

    <>
        <div className="fixed-bottom">
            <SpeechInterface/>

            <div className="btn-group" role="group" aria-label="Font Size Buttons">
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={increaseFontSize}
                >
                    Increase Font Size
                </button>
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={decreaseFontSize}
                >
                    Decrease Font Size
                </button>
            </div>
            <button
        className={`btn btn-success btn-lg btn-block ${isSending ? 'sending-animation' : ''}`}
        style={{ height: `${fontSize + 40}px`, fontSize: `${fontSize + 5}px` }}
        onClick={handleSendOrder}
        disabled={isSending}
      >
                    {isSending ? 'Sending' : 'Send Order'}
                    </button>

        </div>



        </>
          );
};

export default SendOrderButton;
