// App.js
/**
 * Main application component.
 * @module App
 */
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MenuPage from './menu/menuPage';
import LoginPage from './login/loginPage';
import CashierPage from './cashier/cashierPage';

import ManagerHomePage from './manager/managerHomePage';
import ManagerTrendsPage from './manager/managerTrendsPage';
import ManagerTablesPage from './manager/managerTablesPage';
import ManagerFinancialsPage from './manager/managerFinancialsPage';

import MainCustomerPage from './customer/mainCustomerPage';
import ItemPage from './customer/itemPage';
import Cart from './customer/cart';

/**
 * Main application component that defines the routes and their corresponding components.
 * @function App
 * @returns {JSX.Element} The JSX element representing the main application.
 */
function App() {
  return (
    <Router>
      <Routes>
        {/* Home Page */}
        <Route path="/" element={<MenuPage />} />

        {/* Login Page */}
        <Route path="/login" element={<LoginPage />} />

        {/* Cashier Page */}
        <Route path="/cashier" element={<CashierPage />} />

        {/* Manager Pages */}
        <Route path="/manager" element={<ManagerHomePage/>} />
        <Route path="/manager/trends" element={<ManagerTrendsPage/>} />
        <Route path="/manager/tables" element={<ManagerTablesPage/>} />

        {/* Customer Pages */}
        <Route path="/customer" element={<MainCustomerPage />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/customer/:category" element={<ItemPage />} />

      </Routes>
    </Router>
  );
}

export default App;

