import React, { useEffect, useState } from 'react';


/**
 * Navigation bar component allowing users to navigate between menu categories.
 * @param {Object} props - Props object containing handleCategoryChange function.
 * @param {Function} props.handleCategoryChange - Function to handle category change.
 * @returns {JSX.Element} The rendered navigation bar component.
 * @author Caleb Elizondo
 */

const CashierNavigationBar = ({ handleCategoryChange }) => {
    const [menuCategories, setMenuCategories] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let apiUrl;
                if (process.env.NODE_ENV === 'development') {
                    apiUrl = 'http://localhost:8000/datamanagement/getCategories';
                } else {
                    apiUrl = 'https://csce315331-07b.onrender.com/datamanagement/getCategories';
                }

                const response = await fetch(apiUrl);
                const data = await response.json();
                setMenuCategories(data.menu_categories);
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();
    }, []);

    const navigateToRoot = () => {
        window.location.href = '/'; // Redirects to the root URL
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container-fluid">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <button
                            className="nav-link btn btn-outline-dark mx-2"
                            onClick={navigateToRoot}
                        >
                            Back
                        </button>
                    </li>
                </ul>
                <ul className="navbar-nav mx-auto">
                    {menuCategories.map((category) => (
                        <li className="nav-item" key={category}>
                            <button
                                className="nav-link btn btn-outline-dark mx-2"
                                onClick={() => handleCategoryChange(category)}
                            >
                                {category}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        </nav>
    );
};

export default CashierNavigationBar;

