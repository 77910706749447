import React, { useEffect, useState } from 'react';

import "./popup.css";


/**
 * Component displaying a popup for selecting modifications before adding an item to the current order.
 * @param {Object} props - Props object containing selectedItem, closePopup, and addItemToOrder functions.
 * @param {Object} props.selectedItem - The selected item for modification.
 * @param {Function} props.closePopup - Function to close the popup.
 * @param {Function} props.addItemToOrder - Function to add an item to the order with modifications.
 * @returns {JSX.Element} The rendered item popup component.
 * @author Caleb Elizondo
 */
const ItemPopup = ({ selectedItem, closePopup, addItemToOrder }) => {
    const [allIngredients, setAllIngredients] = useState([]);
    const [defaultIngredients, setDefaultIngredients] = useState([]);
    const [additionalIngredients, setAdditionalIngredients] = useState([]);
    

    useEffect(() => {
        // Fetch all ingredients
        console.log("selectedItem", selectedItem);
        const fetchData = async () => {
            try {
                let apiUrl;
                if (process.env.NODE_ENV === 'development') {
                    // Development environment
                    apiUrl = 'http://localhost:8000/datamanagement/getIngredientNames';
                } else {
                    // Production environment
                    apiUrl = 'https://csce315331-07b.onrender.com/datamanagement/getIngredientNames';
                }

                const response = await fetch(apiUrl);
                const data = await response.json();
                setAllIngredients(data);
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        // Extract default ingredients from the selected menu item
        if (selectedItem && selectedItem.ingredients) {
            const defaultIngredientNames = selectedItem.ingredients;
            const defaultIngredientsData = allIngredients.filter(ingredient =>
                defaultIngredientNames.includes(ingredient.name)
            );
            setDefaultIngredients(defaultIngredientsData);

            const additionalIngredientsData = allIngredients.filter(ingredient =>
                !defaultIngredientNames.includes(ingredient.name)
            );
            setAdditionalIngredients(additionalIngredientsData);
        }
    }, [selectedItem, allIngredients]);

    /**
     * Handles adding the modified item to the order.
     */
    const handleAddToOrder = () => {
        let additionalIngredientIDs = additionalIngredients
            .map(ingredient => ingredient.id);
    
        const modifications = [];
        const modifications_strings = [];
        let selectedAdditionalIngredients = [];


    
        // Iterate through default ingredient checkboxes
        defaultIngredients.forEach(ingredient => {
            const checkbox = document.getElementById(`${ingredient.id}`);
            if (checkbox.checked) {
                // Add checked default ingredients to ingredients
                selectedAdditionalIngredients.push(ingredient.id);
            } else {
                // Add unchecked default ingredients as negative ids to modifications
                modifications.push(-ingredient.id);
                modifications_strings.push("RM " + ingredient.name);
            }
        });

        additionalIngredientIDs.forEach(ingredient => {
            const checkbox = document.getElementById(ingredient);
            if (checkbox.checked){
                selectedAdditionalIngredients.push(ingredient);
                modifications.push(ingredient);

                allIngredients.forEach(i => {

                    if (i.id === ingredient){
                        modifications_strings.push("ADD " + i.name);

                    }
                });
                
            }
        })
    
        addItemToOrder({
            name: selectedItem.name,
            menu_item_id: selectedItem.id,
            ingredients: selectedAdditionalIngredients,
            modifications: modifications,
            price: selectedItem.price,
            modifications_strings: modifications_strings, 
            default_ingredients: selectedItem.ingredients, 
            itemId: selectedItem.oldItemId
        });
        closePopup();
    };
    
    /**
     * Toggles ingredient selection.
     * @param {number} ingredientId - The ID of the ingredient to toggle.
     */
    const handleIngredientToggle = (ingredientId) => {
        // Toggle ingredient selection
        const updatedIngredients = allIngredients.map(ingredient =>
            ingredient.id === ingredientId ? { ...ingredient, checked: !ingredient.checked } : ingredient
        );
        setAllIngredients(updatedIngredients);
    };
    
    /**
     * Renders the item popup component.
     * @returns {JSX.Element} The JSX element representing the item popup.
     */
    return (
        <div className="popup">
            <div className="popup-inner">
                <button className="exit-button" onClick={closePopup}>X</button>
                <h2 className="popup-header">{selectedItem.name}</h2>

                <div className="ingredient-section">
                    <div className="default-ingredients">
                        <h3 text-align="right">Remove</h3>
                        {defaultIngredients.map((ingredient) => (
                            <div key={ingredient.id} className="ingredient-item">
                                <label>
                                    <input
                                        id={ingredient.id}
                                        type="checkbox"
                                        defaultChecked
                                        onChange={() => handleIngredientToggle(ingredient.id)}
                                    />
                                    {ingredient.name}
                                </label>
                            </div>
                        ))}
                    </div>

                    <div className="additional-ingredients">
                        <h3>Add</h3>
                        {additionalIngredients.map((ingredient) => (
                            <div key={ingredient.id} className="ingredient-item">
                                <label>
                                    <input
                                        id={ingredient.id}
                                        type="checkbox"
                                        onChange={() => handleIngredientToggle(ingredient.id)}
                                    />
                                    {ingredient.name}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
                <button className="send-order-button" onClick={handleAddToOrder}>Add to order</button>
            </div>
        </div>
    );
};

export default ItemPopup;
