import React from 'react';
import './mainCustomerPage.css';
import { NavLink } from 'react-router-dom';

/**
 * The MenuCategoryButtons component displays the button for the selected category
 * @param {object} category - Category passed in from MainCustomerPage
 * @author Maria Visinescu
 */
const MenuCategoryButtons = ({ category }) => {
  
  // JSX for the category button
  return (
    <>
      <NavLink to={`/customer/${category}`}>
        <button className="button">{category}</button>
      </NavLink>
    </>
  );
}
  
export default MenuCategoryButtons;
