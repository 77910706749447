import { useState } from "react";
import CashierMenuCategoryDisplay from "./cashierCategoryDisplay";
import CashierNavigationBar from "./cashierNavigationBar";
import SendOrderButton from "./sendOrderButton";
import CurrentOrderDisplay from "./currentOrderDisplay";
import ItemPopup from "./ItemPopup";


/**
 * Root component for the Point of Sale (POS) interface.
 * @returns {JSX.Element} The rendered POS interface.
 * @author Caleb Elizondo
 */
const CashierPage = () => {

    document.body.style.overflow = 'hidden';

    const [selectedCategory, setSelectedCategory] = useState('Classic');
    const [cartItems, setCartItems] = useState([]);
    const [itemIdCounter, setItemIdCounter] = useState(1);
    const [editingItem, setEditingItem] = useState(null);
    /**
     * Handles the change of selected category in the menu.
     * @param {string} category - The selected category.
     */
    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
    };
    /**
     * Increments the item ID counter.
     */
    const incrementItemIdCounter = () => {
        setItemIdCounter(prevCounter => prevCounter + 1);
    };

    /**
     * Adds an item to the cart.
     * @param {Object} item - The item to be added to the cart.
     */
    const addToCart = (item) => {
            const newItem = {
                ...item,
                itemId: itemIdCounter, // Add itemId to the item being added
            };

        incrementItemIdCounter();
        setCartItems([...cartItems, newItem])
        console.log("cart", cartItems);
    };

    /**
     * Removes an item from the cart based on its ID.
     * @param {number} itemIdToRemove - The ID of the item to be removed.
     */
    const removeItemFromCart = (itemIdToRemove) => {
        const updatedCart = cartItems.filter(item => item.itemId !== itemIdToRemove);
        setCartItems(updatedCart);
    };

    /**
     * Edits an item from the cart based on its ID.
     * @param {number} itemIdToEdit - The ID of the item to be edited.
     */
    const editItemFromCart = (itemIdToEdit) => {
        const itemToChange = cartItems.filter(item => item.itemId == itemIdToEdit)[0];
        const reformattedItem = {
          id: itemToChange.menu_item_id, 
          ingredients: itemToChange.default_ingredients, 
          name: itemToChange.name, 
          price: itemToChange.price, 
          oldItemId: itemToChange.itemId
        }
        showPopup(reformattedItem);
    };

    /**
     * Replaces an item in the cart with a new one.
     * @param {Object} newItem - The new item to replace in the cart.
     */
    const replaceItemInCart = (newItem) => {

        console.log("edited", newItem);
        setCartItems(prevCartItems => {
            const updatedCartItems = prevCartItems.map(item => {
                if (item.itemId === newItem.itemId) {
                    return newItem; // Replace the item with the new one
                }
                return item; // Return unchanged if the itemId doesn't match
            });
            return updatedCartItems;
        });
    };

    /**
     * Displays the item popup for editing items.
     * @param {Object} item - The item to be edited.
     */
    const showPopup = (item) => {
        setEditingItem(item);
      };
    
      /**
     * Closes the item popup.
     */
      const closePopup = () => {
        setEditingItem(null);
      };
    
    
      /**
     * Sends the order to the server.
     */
    function sendOrder() {

        if (cartItems.length === 0) return;

        let apiUrl;

        if (process.env.NODE_ENV === 'development') {
            // Development environment
            apiUrl = `http://localhost:8000/datamanagement/sendOrder/`;
        } else {
        // Production environment
            apiUrl = `https://csce315331-07b.onrender.com/datamanagement/sendOrder/`;
        }


        fetch(apiUrl, {
        
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ currentOrder: cartItems }),
        })
        .then(response => {
            if (response.ok) {
                console.log('Order sent to Django view');
                // Once the order is successfully sent, reset the currentOrder
                setCartItems([]);
            } else {
                console.error('Failed to send order to Django view');
            }
        })
        .catch(error => console.error('Error:', error));
    };

    const [fontSize, setFontSize] = useState(16); // Initial font size, in pixels

  // Function to increase font size
  const increaseFontSize = () => {
    setFontSize(prevSize => prevSize + 2); // Increase font size by 2 pixels
  };

  // Function to decrease font size
  const decreaseFontSize = () => {
    setFontSize(prevSize => Math.max(prevSize - 2, 12)); // Decrease font size by 2 pixels, with a minimum size of 12px
  };

  // Update the document body's font size based on the state value
  document.body.style.fontSize = `${fontSize}px`;

    return (

        <>
        {editingItem && (
            <ItemPopup selectedItem={editingItem} closePopup={closePopup} addItemToOrder={replaceItemInCart} />
        )}


        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <CashierNavigationBar handleCategoryChange={handleCategoryChange} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <CashierMenuCategoryDisplay
                category={selectedCategory}
                addToCart={addToCart}
                fontSize={fontSize}
              />
            </div>
            <div className="col-md-4">
              <div className="d-flex flex-column justify-content-between" style={{ height: '80vh' }}>
                <div>
                  <div>
                    <CurrentOrderDisplay cartItems={cartItems} removeItem={removeItemFromCart} editItem={editItemFromCart} fontSize={fontSize}/>
                  </div>
                </div>
                <div className="position-fixed bottom-0 start-0 mb-3">
                  <div>
                  </div>
                  <div>
                    
                    <SendOrderButton sendOrder={sendOrder}  fontSize={fontSize} increaseFontSize={increaseFontSize} decreaseFontSize={decreaseFontSize} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </>
      );

      
      
};

export default CashierPage;
