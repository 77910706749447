// CashierMenuItem.js

import React from 'react';

const CashierMenuItem = ({ category, item, showPopup, fontSize }) => {
  const handleItemClick = () => {
    showPopup(item);
  };

  const formattedString = item.name.toLowerCase().replace(/\s+/g, '-');

  let apiUrl;
  if (process.env.NODE_ENV === 'development') {
    apiUrl = `http://localhost:8000/static/${category}/${formattedString}.jpg`;
  } else {
    apiUrl = `https://csce315331-07b.onrender.com/static/${category}/${formattedString}.jpg`;
  }

  let imageUrl = apiUrl;

  return (
    <button
      onClick={handleItemClick}
      className="btn btn-primary rounded-lg py-3 px-4 my-2 w-100 text-center"
      style={{
        fontSize: `${fontSize}px`, // Use the passed fontSize prop here
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {item.name}
    </button>
  );
};

export default CashierMenuItem;
