import React from 'react';
import './dashboard.css';

const Dashboard = ({ fontSize, setFontSize }) => {
  const handleFontSizeChange = event => {
    setFontSize(parseInt(event.target.value)); // Update font size based on the slider value
  };

  return (
    <div className="dashboard-container">
      <span>Font Size: </span>
      <input
        type="range"
        min="12" 
        max="32" 
        value={fontSize}
        onChange={handleFontSizeChange}
        className="slider"
      />
      
    </div>
  );
};

export default Dashboard;