import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './managerPages.css';

const ManagerRestockReport = () => {
  const [restockItems, setRestockItems] = useState([]);

  const fetchData = async () => {
    try {
      let apiUrl;
      if (process.env.NODE_ENV === 'development') {
        apiUrl = 'http://localhost:8000/datamanagement/managerRestockReport/';
      } else {
        apiUrl = 'https://csce315331-07b.onrender.com/datamanagement/managerRestockReport/';
      }

      console.log('API URL:', apiUrl);

      const response = await fetch(apiUrl);
      const data = await response.json();
      console.log('Data from API:', data);

      setRestockItems(data.restock_items);
    } catch (error) {
      console.error(`Error fetching restock items data: ${error.message}`);
    }
  };

  useEffect(() => {
    // Fetch data on component mount
    fetchData();
  }, []);

  return (
    <div className="scrollable-tbody">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Ingredient ID</th>
            <th>Ingredient Name</th>
            <th>Type</th>
            <th>Amount</th>
            <th>Standard Amount</th>
          </tr>
        </thead>
        <tbody className="scrollable-tbody">
          {restockItems.map((restockItem) => (
            <tr key={restockItem.id}>
              <td>{restockItem.id}</td>
              <td>{restockItem.name}</td>
              <td>{restockItem.type}</td>
              <td>{restockItem.amount}</td>
              <td>{restockItem.standard_amount}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ManagerRestockReport;
