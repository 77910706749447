import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import './loginPage.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import Chatbot from "react-chatbot-kit";
import config from "./config";
import MessageParser from "./MessageParser";
import ActionProvider from "./ActionProvider";
import 'react-chatbot-kit/build/main.css';
import './chatbot.css'
/**
 * LoginPage component - handles user authentication, including login and logout functionalities.
 * This component also includes options for Google OAuth login and a chatbot interface.
 * @author Patralika Ghosh
 */
const LoginPage = () => {
  // Hooks for navigation and state management
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [managerStatus, setmanagerStatus] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [userType, setUserType] = useState(null);
  const [isLoggedInGoogle, setLoggedInGoogle] = useState(false);
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);

  // Hardcoded users data - typically you would fetch this from a server
  const [users, setUsers] = useState([
    { username: 'sophiae', password: 'password', managerStatus: true },
    { username: 'calebe', password: 'password', managerStatus: true },
    { username: 'patralikag', password: 'password', managerStatus: true },
    { username: 'inwoos', password: 'password', managerStatus: true },
    { username: 'mariav', password: 'password', managerStatus: true },
  ]);

  // Effect to check local storage for login state on component mount
  useEffect(() => {
    const storedLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    setLoggedIn(storedLoggedIn);

    const storedLoggedInGoogle = localStorage.getItem('isLoggedInGoogle') === 'true';
    setLoggedInGoogle(storedLoggedInGoogle);

    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      setUsername(storedUsername);
    }


  }, []);  

    // Effect to navigate user if logged in with Google
    useEffect(() => {
      if (isLoggedInGoogle) {
        navigate('/customer');
      }
    }, [isLoggedInGoogle, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let apiUrl;
        if (process.env.NODE_ENV === 'development') {
          apiUrl = `http://localhost:8000/datamanagement/getEmployees`;
        } else {
          apiUrl = `https://csce315331-07b.onrender.com/datamanagement/getEmployees`;
        }
  
        const response = await fetch(apiUrl);
        const data = await response.json();
  
        // Append users after rows have been updated
        appendUsersFromEmployees(data.employees);
        console.log('Updated Users:', users);
      } catch (error) {
        console.error('Error:', error);
      }
    };
  
    fetchData(); // Fetch data first
  }, []);

  const appendUsersFromEmployees = (employees) => {
    if (Array.isArray(employees)) {
      const newUsers = employees.map((employee) => ({
        username: employee.name,
        password: 'password',
        managerStatus: false,
      }));
  
      setUsers((prevUsers) => [...prevUsers, ...newUsers]);
    }
  };


  /**
   * Finds a user by username.
   * @param {string} uname - Username to search for.
   * @returns {object|null} - User object if found, null otherwise.
   * @author Patralika Ghosh
   */
  const findUserByUsername = (uname) => {
    return users.find((user) => user.username === uname);
  };

    /**
   * Handles user login.
   * Validates user credentials and updates state accordingly.
   * @author Patralika Ghosh
   */
  const handleLogin = () => {
    const user = findUserByUsername(username);

    if (user && user.password === password) {
      localStorage.setItem('isLoggedIn', 'true');
      localStorage.setItem('username', username);
      setLoggedIn(true);
      setmanagerStatus(user.managerStatus);
    } else {
      alert('Invalid username or password');
    }
  };

    /**
   * Handles user logout.
   * Resets state and optionally navigates to the login page.
   * @author Patralika Ghosh
   */
  const handleLogout = () => {
    setLoggedIn(false);
    // Optionally, you can navigate to the login page after logout
    navigate('/');
    localStorage.removeItem('isLoggedIn');
    alert("You have been logged out.");
  };

    /**
   * Navigates to the specified path.
   * @param {string} path - The path to navigate to.
   * @author Patralika Ghosh
   */
  const handleNavigate = (path) => {
    navigate(path);
  };

  // JSX for the login page
  return (
    <>
      <div className="top-bar">
        <div>
          <NavLink to="/" style={({ isActive }) => ({ color: isActive ? 'pink' : 'white' })}>
            Home
          </NavLink>
        </div>
        <div id="google_translate_element">Google Translate</div> 
        <div>
          {isLoggedIn ? (
            <button className="logout-button" onClick={handleLogout}>
              Logout
            </button>
          ) : (
            <NavLink to="/login" style={({ isActive }) => ({ color: isActive ? 'pink' : 'white' })}>
              Log In
            </NavLink>
          )}
        </div>
      </div>

      <div className="login-container">

        { !isLoggedIn && (
        <div className="user-type-selection">
          <p >Are you a Customer or Employee?</p>
          <button className="nav-button" onClick={() => setUserType('employee')}>Employee</button>
          <button className="nav-button" onClick={() => setUserType('customer')}>Customer</button>
        </div>
        )}
          
          {(!isLoggedIn && userType === 'employee') ? (
            <div className="login-box">
              <>
                <input
                  className="login-input"
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <input
                  className="login-input"
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button className="login-button" onClick={handleLogin}>
                  Log In
                </button>
              </>
            </div>
          ) : null}

          {(!isLoggedInGoogle && !isLoggedIn && userType === 'customer') && (
            <GoogleOAuthProvider clientId="568849202544-5l7s5fkg5rr14jrgq317qek8dbl6r573.apps.googleusercontent.com">

            <GoogleLogin
              onSuccess={credentialResponse => {
                const decoded = jwtDecode(credentialResponse.credential);
                localStorage.setItem('isLoggedInGoogle', 'true');
                setLoggedInGoogle(true);
                console.log(decoded);
              }}
              onError={() => {
                console.log('Login Failed');
              }}
            />

            </GoogleOAuthProvider>
          )}

          {!isLoggedIn && (
          <button 
            className="nav-button" 
            onClick={() => setIsChatbotOpen(!isChatbotOpen)}
          >
            {isChatbotOpen ? 'Close Chat' : 'Chat with us!'}
          </button>
          )}

          {isChatbotOpen && (
            <div className="chatbot-container-right">
            <Chatbot
              config={config}
              messageParser={MessageParser}
              actionProvider={ActionProvider}
            />
            </div>
          )}
          
          {isLoggedIn && (
            <div className="login-success">
              
              <div className="login-button-container">
              <div>
                <button 
                  className="nav-button" 
                  onClick={() => setIsChatbotOpen(!isChatbotOpen)}
                >
                  {isChatbotOpen ? 'Close Chat' : 'Chat with us!'}
                </button>
              </div>

              <div>
                <button className="nav-button" onClick={() => handleNavigate('/customer')}>
                  Continue to Customer Page
                </button>
              </div>
              
              
              <div>
                <button className="nav-button" onClick={() => handleNavigate('/cashier')}>
                  Continue to Cashier Page
                </button>
              </div>
              
              {users.map((user) => (
                user.username === username && user.managerStatus && (
                  <div key={user.username}>
                    <button className="nav-button" onClick={() => handleNavigate('/manager')}>
                      Continue to Manager Page
                    </button>
                  </div>
                )
              ))}

              </div>
            </div>
          )}
      </div>
    </>
  );
};

export default LoginPage;