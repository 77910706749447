import React, { useState, useEffect } from 'react';
import { Container, Dropdown, Table} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ManagerNavigationBar from './managerNavigationBar';
import './managerPages.css';
import ManagerEmployeeTable from './managerEmployeeTable';
import ManagerMenuTable from './managerMenuTable';
import ManagerInventoryTable from './managerInventoryTable';
import ManagerOrderTable from './managerOrderTable';

/**
 * ManagerTablesPage is the basic page for the tables tab of the manager view
 * It contains a dropdown menu that allows the user to choose what table they would like to see
 * It then displays the chosen component from the dropdown menu
 * @author Sophia Evanisko
 */
const ManagerTablesPage = () => {
  const [selectedOption, setSelectedOption] = useState('inventory');
  const [menuItems, setMenuItems] = useState([]);
  const [inventoryItems, setInventoryItems] = useState([]);
  const [employeeItems, setEmployeeItems] = useState([]);
  const [orderItems, setOrderItems] = useState([]);

  
    /**
     * When a new dropdown item is chosen, the selected menu option is updated
     */
    const handleDropdownChange = async (eventKey) => {
      setSelectedOption(eventKey);
  
      try {
        let apiUrl;
        if (process.env.NODE_ENV === 'development') {
          apiUrl = `http://localhost:8000/datamanagement/get${eventKey.charAt(0).toUpperCase() + eventKey.slice(1)}`;
        } else {
          apiUrl = `https://csce315331-07b.onrender.com/datamanagement/get${eventKey.charAt(0).toUpperCase() + eventKey.slice(1)}`;
        }
  
        const response = await fetch(apiUrl);
        const data = await response.json();
  
        switch (eventKey) {
          case 'inventory':
            setInventoryItems(data.inventory);
            break;
          case 'menuItems':
            setMenuItems(data.menu_items);
            break;
          case 'employees':
            setEmployeeItems(data.employees);
            break;
          case 'orders':
            setOrderItems(data.orders);
            break;
          default:
            break;
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

  
  /**
   * Fetches the relevant data from the database each time the page is loaded
   */
  useEffect(() => {
    const fetchData = async () => {
      try {
        let apiUrl;
        if (process.env.NODE_ENV === 'development') {
          apiUrl = `http://localhost:8000/datamanagement/getMenuItems`;
        } else {
          apiUrl = `https://csce315331-07b.onrender.com/datamanagement/getMenuItems`;
        }

        const response = await fetch(apiUrl);
        const data = await response.json();
        console.log(data);
        setMenuItems(data.menu_items);
      } catch (error) {
        console.error('Error:', error);
      }

      try {
        let apiUrl;
        if (process.env.NODE_ENV === 'development') {
          apiUrl = `http://localhost:8000/datamanagement/getEmployees`;
        } else {
          apiUrl = `https://csce315331-07b.onrender.com/datamanagement/getEmployees`;
        }

        const response = await fetch(apiUrl);
        const data = await response.json();

        setEmployeeItems(data.employees);
      } catch (error) {
        console.error('Error:', error);
      }

      try {
        let apiUrl;
        if (process.env.NODE_ENV === 'development') {
          apiUrl = `http://localhost:8000/datamanagement/getInventory`;
        } else {
          apiUrl = `https://csce315331-07b.onrender.com/datamanagement/getInventory`;
        }

        const response = await fetch(apiUrl);
        const data = await response.json();

        setInventoryItems(data.inventory);
      } catch (error) {
        console.error('Error:', error);
      }

      try {
        let apiUrl;
        if (process.env.NODE_ENV === 'development') {
          apiUrl = `http://localhost:8000/datamanagement/getOrders`;
        } else {
          apiUrl = `https://csce315331-07b.onrender.com/datamanagement/getOrders`;
        }

        const response = await fetch(apiUrl);
        const data = await response.json();

        setOrderItems(data.orders);
      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchData();
  }, []);


  
    /**
     * Renders the table chosen in the dropdown menu by loading in that component class
     */
  const renderTable = () => {
    switch (selectedOption) {
      case 'inventory':
        return (
           <ManagerInventoryTable inventory={inventoryItems}/>
        );

      case 'menuItems':
        return (
            <ManagerMenuTable menu_items={menuItems} ingredients= {inventoryItems}/>
        );

      case 'employees':
        return (
            <ManagerEmployeeTable employees={employeeItems}/>
        );

      case 'orders':
        return (
            <ManagerOrderTable orders={orderItems}/>
        );

      default:
        return null;
    }
  };

  return (
    <div>
        <ManagerNavigationBar />
        <div className='text-center'>
            <div className='d-flex justify-content-center'>
                <Container>
                    <label htmlFor="tableSelector">Select a table:</label>
                    <Dropdown onSelect={handleDropdownChange}>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            {selectedOption}
                        </Dropdown.Toggle>

                        <Dropdown.Menu style={{ maxHeight: '600px', overflowY: 'auto' }}>
                            <Dropdown.Item eventKey="inventory">Inventory</Dropdown.Item>
                            <Dropdown.Item eventKey="employees">Employees</Dropdown.Item>
                            <Dropdown.Item eventKey="menuItems">Menu</Dropdown.Item>
                            <Dropdown.Item eventKey="orders">Orders</Dropdown.Item>
                            {/* Add more Dropdown.Items for additional tables as needed */}
                        </Dropdown.Menu>
                    </Dropdown>
                </Container>
            </div>
            
            <div className='table-container'>
                {renderTable()}
            </div>
        </div>
    </div>
  );
};

export default ManagerTablesPage;
