// WeatherWidget.js
import React, { useEffect } from 'react';

const WeatherWidget = () => {
  useEffect(() => {
    // Weather Widget Script
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://weatherwidget.io/js/widget.min.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup the script when the component is unmounted
      document.body.removeChild(script);
    };
  }, []);

  return (
    <a
      className="weatherwidget-io"
      href="https://forecast7.com/en/30d63n96d33/college-station/?unit=us"
      data-label_1="COLLEGE STATION"
      data-label_2="WEATHER"
      data-theme="original"
    >
      COLLEGE STATION WEATHER
    </a>
  );
};

export default WeatherWidget;
