import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom'; // Import useParams
import WeatherWidget from '../weatherWidget';
import './itemPage.css';
import Item from './item.js';
import { googleLogout } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';

/**
 * The ItemPage component displays all the items within the given category
 * @author Maria Visinescu
 * @author Patralika Ghosh
 */
const ItemPage = () => {
  // Hooks for state management
  const { category } = useParams(); // Use useParams to get the category from the URL
  const [menuItems, setMenuItems] = useState([]);
  const [isLoggedInGoogle, setLoggedInGoogle] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(localStorage.getItem('isLoggedIn'));
  const navigate = useNavigate();

  // Effect to fetch the data
  useEffect(() => {
    const fetchData = async () => {
      try {
        let apiUrl;
        if (process.env.NODE_ENV === 'development') {
          // Development environment
          apiUrl = `http://localhost:8000/datamanagement/getMenuItemsByCategory/${category}`;
        } else {
          // Production environment
          apiUrl = `https://csce315331-07b.onrender.com/datamanagement/getMenuItemsByCategory/${category}`;
        }

        const response = await fetch(apiUrl);
        const data = await response.json();

        // Sort menu items alphabetically by name
        const sortedMenuItems = data.menu_items.sort((a, b) =>
          a.name.localeCompare(b.name)
        );

        setMenuItems(sortedMenuItems);
      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchData();
  }, [category]); // Include category in the dependency array

  /**
   * Function to log out the customer using oauth
   */
  const handleGoogleSignOut = () => {
    googleLogout();
    localStorage.removeItem('isLoggedInGoogle');
    setLoggedInGoogle(false);
    navigate('/');
    alert("You have been logged out.");
  };

  /**
   * Function that handles user logout.
   * Resets state and optionally navigates to the login page.
   */
  const handleLogout = () => {
    setLoggedIn(false);
    // Optionally, you can navigate to the login page after logout
    navigate('/');
    localStorage.removeItem('isLoggedIn');
    alert("You have been logged out.");
  };

  // JSX for the items display within the given category
  return (
    <>
      <div className="top-bar">
        <div>
          <NavLink to="/" style={({ isActive }) => ({ color: isActive ? 'pink' : 'white' })}>
            Home
          </NavLink>
        </div>
        <div id="google_translate_element">Google Translate</div> 
        <div>
          {isLoggedIn ? (
            <button className="logout-button" onClick={handleLogout}>
              Logout
            </button>
          ) : (
            <button className="logout-button" onClick={handleGoogleSignOut}>
              Logout
            </button>
          )}
        </div>
      </div>

      {/* Include the WeatherWidget component */}
      <WeatherWidget />

      <h1 className="item-header">{ category } Drinks</h1>
      
      <div className="item-container">
        {menuItems.map((item) => (
          <Item key={item.id} category={category} item={item}/>
        ))}
      </div>

      <button className="back">
        Back
      </button>

      <NavLink to="/customer">
        <button className="back">
          Back
        </button>
      </NavLink>

      <NavLink to="/cart">
        <button className="view-cart">
          View Cart
        </button>
      </NavLink>
    </>
  );
};

export default ItemPage;
