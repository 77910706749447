import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import MenuCategoryButtons from './menuCategoryButtons';
import { useNavigate } from 'react-router-dom';
import WeatherWidget from '../weatherWidget';
import './mainCustomerPage.css';
import { googleLogout } from '@react-oauth/google';
import Chatbot from "react-chatbot-kit";
import config from "../login/config";
import MessageParser from "../login/MessageParser";
import ActionProvider from "../login/ActionProvider";
import 'react-chatbot-kit/build/main.css';
import '../login/chatbot.css'

/**
 * The MainCustomerPage component displays all the possible drink categories
 * Each category button will then lead to their own pages when clicked
 * @author Maria Visinescu
 * @author Patralika Ghosh
 */
const MainCustomerPage = () => {
  // Hooks for state management
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);
  const buttonClass = `chatbot-box ${isChatbotOpen ? 'chatbot-box-right' : ''}`;
  const navigate = useNavigate();
  const [menuCategories, setMenuCategories] = useState([]);
  const location = useLocation();
  const [isLoggedInGoogle, setLoggedInGoogle] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(localStorage.getItem('isLoggedIn'));
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false
      },
      "google_translate_element"
    );
  };

  // Effect to fetch the data
  useEffect(() => {
    const fetchData = async () => {
      try {
        let apiUrl;
        if (process.env.NODE_ENV === 'development') {
          // Development environment
          apiUrl = 'http://localhost:8000/datamanagement/getCategories';
        } else {
          // Production environment
          // Use the actual production API URL here
          apiUrl = 'https://csce315331-07b.onrender.com/datamanagement/getCategories';
        }

        const response = await fetch(apiUrl);
        const data = await response.json();
        setMenuCategories(data.menu_categories);
        // console.log(data.menu_categories);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []); // Empty dependency array means this effect runs once after the initial render

  /**
   * Function to log out the customer using oauth
   */
  const handleGoogleSignOut = () => {
    googleLogout();
    localStorage.removeItem('isLoggedInGoogle');
    setLoggedInGoogle(false);
    navigate('/');
    alert("You have been logged out.");
  };

  /**
   * Function that handles user logout.
   * Resets state and optionally navigates to the login page.
   */
  const handleLogout = () => {
    setLoggedIn(false);
    // Optionally, you can navigate to the login page after logout
    navigate('/');
    localStorage.removeItem('isLoggedIn');
    alert("You have been logged out.");
  };

  // JSX for the main customer page
  return (
    <>
      <div className="top-bar">
        <div>
          <NavLink to="/" style={({ isActive }) => ({ color: isActive ? 'pink' : 'white' })}>
            Home
          </NavLink>
        </div>
        <div id="google_translate_element">Google Translate</div> 
        <div>
          {isLoggedIn ? (
            <button className="logout-button" onClick={handleLogout}>
              Logout
            </button>
          ) : (
            <button className="logout-button" onClick={handleGoogleSignOut}>
              Logout
            </button>
          )}
        </div>
      </div>

      {/* Include the WeatherWidget component */}
      <WeatherWidget />

      <div className="button-container">
        <div className="order-text">
          <p style={{ fontSize: '35px', fontWeight: 'bold' }}>Order Here:</p>
          <p style={{ fontSize: '27px', fontWeight: 'bold' }}>Please select a category</p>
        </div>
        
        <div>
          {menuCategories.map(category => (
            <MenuCategoryButtons category={category} key={category.id} />
          ))}
        </div>

      </div>

      <div className="extra-space"></div>
      
      <button 
        className={buttonClass}
        onClick={() => setIsChatbotOpen(!isChatbotOpen)}
      >
        {isChatbotOpen ? 'Close Chat' : 'Chat with us!'}
      </button>

          {isChatbotOpen && (
            <div className="chatbot-container-left">
            <Chatbot
              config={config}
              messageParser={MessageParser}
              actionProvider={ActionProvider}
            />
            </div>
          )}

      <NavLink to="/cart">
        <button className="view-cart">
          View Cart
        </button>
      </NavLink>
    </>
  );
};

export default MainCustomerPage;
