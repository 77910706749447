import React, { useState, useEffect } from 'react';
import { Table, Button, Form, Row, FormCheck, Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './managerPages.css';

/**
 * ManagerMenuTable class displays the menu table in the manager view
 * It handles adding, editing, deleting and updating the menu table and fetching the relevant data from the django backend
 * @param {object|null} menu_items - The MenuItem objects retrieved from the Django backend
 * @author Sophia Evanisko
 */
const ManagerMenuTable = ({ menu_items, ingredients }) => {
    const [rows, setRows] = useState(menu_items);
    const [ings, setIngs] = useState(ingredients)
    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [newMenuItem, setNewMenuItem] = useState({ category: '', name: '', price:'', id: '' });
    const [selectedItems, setSelectedItems] = useState([]);

    const handleCheckboxChange = (item) => {
        const isSelected = selectedItems.includes(item);
    
        if (isSelected) {
          setSelectedItems(selectedItems.filter((selectedItem) => selectedItem !== item));
        } else {
          setSelectedItems([...selectedItems, item]);
        }
    };
    

    const refreshTable = async () => {
        try {
            let apiUrl;
            if (process.env.NODE_ENV === 'development') {
                apiUrl = `http://localhost:8000/datamanagement/getMenuItems`;
            } else {
                apiUrl = `https://csce315331-07b.onrender.com/datamanagement/getMenuItems`;
            }

            const response = await fetch(apiUrl);
            const data = await response.json();
            menu_items = data.menu_items;
            setRows(data.menu_items);
        } catch (error) {
            console.error('Error:', error);
        }
    };


    /**
       * Will delete the entry with the given id from the table and update the display
       * @param {*} id - the id of the entry to be deleted
       * @param {*} table - a string representing the purpose of the table i.e. "menu"
       */
    const deleteRow = async (id, table) => {
      console.log("Attempting to delete " + id);

      try {
        let apiUrl;
        if (process.env.NODE_ENV === 'development') {
          apiUrl = `http://localhost:8000/datamanagement/deleteRow/${id}/${table}`;
        } else {
          apiUrl = `https://csce315331-07b.onrender.com/datamanagement/deleteRow/${id}/${table}`;
        }

        // Make a DELETE request to your Django backend to delete the menu item
        await fetch(apiUrl);

        // Update the local state by removing the deleted menu item
        refreshTable();
      } catch (error) {
        console.error(`Error deleting menu item: ${error.message}`);
      }
    };

    /**
     * Hides the edit menuitem form and displays the add menuitem form
     */
    const handleAddMenuItem = () => {
        setShowAddForm(true);
        setShowEditForm(false);
    };

    /**
     * Hides the add menuitem form and displays the edit menuitem form
     */
    const handleEditMenuItem = () => {
        setShowAddForm(false);
        setShowEditForm(true);
    }


    const handleAddFormSubmit = async (event) => {
        event.preventDefault();
        // Access the name value from newEmployee state
        const { category, name, price } = newMenuItem;

        console.log(selectedItems);

        try {
            let apiUrl;
            if (process.env.NODE_ENV === 'development') {
                apiUrl = `http://localhost:8000/datamanagement/addMenuItem/`;
            } else {
                apiUrl = `https://csce315331-07b.onrender.com/datamanagement/addMenuItem/`;
            }

            // Make a post request to Django backend to add the employee
            const response = await fetch(apiUrl, {
                method: 'POST',
                body: JSON.stringify({ category: category, name: name, price: price, ingredients:selectedItems}),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = await response.json();

            // Reset the form and hide it
            setNewMenuItem({ category: '', name: '' });
            setShowAddForm(false);

            refreshTable();
        } catch (error) {
            console.error(`Error adding menu item: ${error.message}`);
        }
    };
    

    const handleEditFormSubmit = async (event) => {
        event.preventDefault();
        // Access the name value from newEmployee state
        const { category, name, price, id } = newMenuItem;

        console.log(selectedItems);

        try {
            let apiUrl;
            if (process.env.NODE_ENV === 'development') {
                apiUrl = `http://localhost:8000/datamanagement/editMenuItem/`;
            } else {
                apiUrl = `https://csce315331-07b.onrender.com/datamanagement/editMenuItem/`;
            }

            // Make a post request to Django backend to add the employee
            const response = await fetch(apiUrl, {
                method: 'POST',
                body: JSON.stringify({ category: category, name: name, price: price, ingredients:selectedItems, id:id}),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = await response.json();

            // Reset the form and hide it
            setNewMenuItem({ category: '', name: '' });
            setShowEditForm(false);

            refreshTable();
        } catch (error) {
            console.error(`Error adding menu item: ${error.message}`);
        }
    };



    /**
     * Update the local state when the menuitems prop changes
     */
    useEffect(() => {
        setRows(menu_items);
        setIngs(ingredients);
    }, [menu_items, ingredients]);


  return (
    <div>
        <div>
            <Button variant="info" id="button-basic" onClick={() => handleAddMenuItem()}>Add Menu Item</Button>
            <Button variant="warning" id="button-basic" onClick={() => handleEditMenuItem()}>Edit Menu Item</Button>
        </div>

        <div>
            {showAddForm && (
                <div> 
                    <Form>
                        <Row>
                            <Form.Group style={{ display: 'flex', flexDirection: 'row' }}>
                                <Form.Control
                                    className="input-field"
                                    type="text"
                                    placeholder="Category"
                                    value={newMenuItem.category}
                                    onChange={(e) => setNewMenuItem({ ...newMenuItem, category: e.target.value })}
                                />
                                <Form.Control
                                    className="input-field"
                                    type="text"
                                    placeholder="Name"
                                    name="name"
                                    value={newMenuItem.name}
                                    onChange={(e) => setNewMenuItem({ ...newMenuItem, name: e.target.value })}
                                />
                                <Dropdown className="input-field">
                                    <Dropdown.Toggle variant="secondary" id="checkbox-dropdown">
                                        Select Ingredients
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                            {ings.map((item) => (
                                                <Form.Check
                                                key={item.name}
                                                type="checkbox"
                                                label={item.name}
                                                checked={selectedItems.includes(item.id)}
                                                onChange={() => handleCheckboxChange(item.id)}
                                                />
                                            ))}
                                    </Dropdown.Menu>
                                </Dropdown>

                                <Form.Control
                                    className="input-field"
                                    type="number"
                                    placeholder="Price"
                                    value={newMenuItem.price}
                                    onChange={(e) => setNewMenuItem({ ...newMenuItem, price: e.target.value })}
                                />
                                <Button
                                    className="submit-button input-field"
                                    value="submit"
                                    type="submit"
                                    onClick={(e) => handleAddFormSubmit(e)}
                                >
                                    submit
                                </Button>
                            </Form.Group>
                        </Row>
                    
                    </Form>
                </div>

            )}
        </div>

        <div>
              {showEditForm && (
                  <div> 
                        <Form>
                            <Row>
                                <Form.Group style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Form.Control
                                        className="input-field"
                                        type="text"
                                        placeholder="id"
                                        name="id"
                                        value={newMenuItem.id}
                                        onChange={(e) => setNewMenuItem({ ...newMenuItem, id: e.target.value })}
                                    />
                                    <Form.Control
                                        className="input-field"
                                        type="text"
                                        placeholder="Category"
                                        value={newMenuItem.category}
                                        onChange={(e) => setNewMenuItem({ ...newMenuItem, category: e.target.value })}
                                    />
                                    <Form.Control
                                        className="input-field"
                                        type="text"
                                        placeholder="Name"
                                        name="name"
                                        value={newMenuItem.name}
                                        onChange={(e) => setNewMenuItem({ ...newMenuItem, name: e.target.value })}
                                    />
                                    <Dropdown className="input-field">
                                        <Dropdown.Toggle variant="secondary" id="checkbox-dropdown">
                                            Select Ingredients
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                                {ings.map((item) => (
                                                    <Form.Check
                                                    key={item.name}
                                                    type="checkbox"
                                                    label={item.name}
                                                    checked={selectedItems.includes(item.id)}
                                                    onChange={() => handleCheckboxChange(item.id)}
                                                    />
                                                ))}
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Form.Control
                                        className="input-field"
                                        type="number"
                                        placeholder="Price"
                                        value={newMenuItem.price}
                                        onChange={(e) => setNewMenuItem({ ...newMenuItem, price: e.target.value })}
                                    />
                                    <Button
                                        className="submit-button input-field"
                                        value="submit"
                                        type="submit"
                                        onClick={(e) => handleEditFormSubmit(e)}
                                    >
                                        submit
                                    </Button>
                                </Form.Group>
                            </Row>
                        
                        </Form>
                  </div>

              )}
         </div>

        <div className="scrollable-tbody">
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Menu Type</th>
                        <th>Name</th>
                        <th>Ingredients</th>
                        <th>Price</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((menuitem) => (
                        <tr key={menuitem.id}>
                        <td>{menuitem.id}</td>
                        <td>{menuitem.menu_type}</td>
                        <td>{menuitem.name}</td>
                        <td>{menuitem.ingredients}</td>
                        <td>{menuitem.price}</td>
                        <td>
                            <Button
                            variant="danger"
                            onClick={() => deleteRow(menuitem.id, "menuitem")}
                            >
                            Delete
                            </Button>
                        </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    </div>
    
  );
};

export default ManagerMenuTable;
