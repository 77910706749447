import React, { useState, useEffect } from 'react';
import { Table, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './managerPages.css';

const ManagerExcessReport = () => {
  const [excessItems, setExcessItems] = useState([]);
  const [timestamp, setTimestamp] = useState('');
  const [validationError, setValidationError] = useState('');

  const fetchData = async () => {
    try {
      let apiUrl;
      if (process.env.NODE_ENV === 'development') {
        apiUrl = `http://localhost:8000/datamanagement/managerExcessReport/${timestamp}`;
      } else {
        apiUrl = `https://csce315331-07b.onrender.com/datamanagement/managerExcessReport/${timestamp}`;
      }
  
      console.log('API URL:', apiUrl);
  
      const response = await fetch(apiUrl);
      const data = await response.json();
      console.log('Data from API:', data);
  
      setExcessItems(data.excess_items);
    } catch (error) {
      console.error(`Error fetching excess items data: ${error.message}`);
    }
  };
  

  const handleLoadReportClick = async () => {
    // Validate the timestamp format
    if (!isValidDateFormat(timestamp)) {
      setValidationError('Invalid timestamp format. Please use YYYY-MM-DD.');
      return;
    }
    // Fetch data when the "Load Report" button is clicked
    await fetchData();

    setValidationError('');

    // Log the selected timestamp
    console.log(`Timestamp: ${timestamp}`);
  };

  const isValidDateFormat = (dateString) => {
    const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
    return dateFormatRegex.test(dateString);
  };

  return (
    <div>
      <label htmlFor="timestamp">Timestamp:</label>
      <input
        type="text"
        id="timestamp"
        value={timestamp}
        onChange={(e) => setTimestamp(e.target.value)}
        placeholder="YYYY-MM-DD"
      />

      <Button variant="primary" onClick={handleLoadReportClick}>
        Load Report
      </Button>

      <div className="scrollable-tbody">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Ingredient ID</th>
              <th>Ingredient Name</th>
              <th>Type</th>
              <th>Amount</th>
              <th>Standard Amount</th>
            </tr>
          </thead>
          <tbody className="scrollable-tbody">
            {excessItems.map((excessItem) => (
              <tr key={excessItem.id}>
                <td>{excessItem.id}</td>
                <td>{excessItem.name}</td>
                <td>{excessItem.type}</td>
                <td>{excessItem.amount}</td>
                <td>{excessItem.standard_amount}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default ManagerExcessReport;
