/**
 * MessageParser class for processing user input and determining the appropriate response.
 * This class analyzes the user's message and triggers corresponding actions in the chatbot.
 */
class MessageParser {
  /**
   * Constructor for the MessageParser class.
   * @param {object} actionProvider - The action provider that contains the methods for handling different types of user input.
   * @author Patralika Ghosh
   */
    constructor(actionProvider) {
      this.actionProvider = actionProvider;
    }
    
  /**
   * Parses the user's message and triggers corresponding actions.
   * The method converts the message to lowercase and checks for key phrases to determine the response.
   * @param {string} message - The user's message to the chatbot.
   * @author Patralika Ghosh
   */
    parse(message) {
      const lowerCaseMessage = message.toLowerCase();
      
      // Checks for greetings and responds with a greeting message
      if (lowerCaseMessage.includes("hello") || lowerCaseMessage.includes("hey") || lowerCaseMessage.includes("hi")) {
        this.actionProvider.greet();
      } 
      
      // Checks for mentions of accessibility and responds with accessibility information
      else if (lowerCaseMessage.includes("accessibility")) {
        this.actionProvider.handleacc();
      } 

      // Checks for drink recommendations queries
      else if (lowerCaseMessage.includes("drink") || lowerCaseMessage.includes("recommend")) {
        this.actionProvider.handleDrink();
      }

      // Checks for coffee-related queries
      else if (lowerCaseMessage.includes("coffee")) {
        this.actionProvider.handleCoffee();
      }

      // Checks for tea-related queries
      else if (lowerCaseMessage.includes("tea")) {
        this.actionProvider.handleTea();
      }

      // Checks for slush-related queries
      else if (lowerCaseMessage.includes("slush")) {
        this.actionProvider.handleSlush();
      }

       // Checks for login-related queries
      else if (lowerCaseMessage.includes("log")) {
        this.actionProvider.handleLogIn();
      }
      
      // Checks for sign-up related queries
      else if (lowerCaseMessage.includes("sign")) {
        this.actionProvider.handleSignUp();
      }

      // Additional checks for customer and employee sign-up queries
      else if (lowerCaseMessage.includes("customer")) {
        this.actionProvider.handleSignUp();
      }

      else if (lowerCaseMessage.includes("employees")) {
        this.actionProvider.handleSignUp();
      }

      // Checks for queries about the bot's preferences
      else if (lowerCaseMessage.includes("your")||lowerCaseMessage.includes("favorite") || lowerCaseMessage.includes("favourite")) {
        this.actionProvider.handleFav();
      }
      else {
        this.actionProvider.handleUnknown();
      }
    }
  }
  
  export default MessageParser;
  