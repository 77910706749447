import ManagerNavigationBar from "./managerNavigationBar";
import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './managerPages.css'

/**
 * ManagerHomePage displays the home page when a user navigates to the manager view
 * It contains a carousel to display images and a navigation bar so users can navigate to other manager pages
 * @author Sophia Evanisko
 */
const ManagerHomePage = () => {
    let apiUrl1, apiUrl2, apiUrl3;

    if (process.env.NODE_ENV === 'development') {
        // Development environment
        apiUrl1 = `http://localhost:8000/static/managerpics/storephoto.jpg`;
        apiUrl2 = `http://localhost:8000/static/managerpics/kungfuteabanner.jpg`;
        apiUrl3 = `http://localhost:8000/static/managerpics/teadrinks.jpg`;
    } else {
        // Production environment
        apiUrl1 = `https://csce315331-07b.onrender.com/static/managerpics/storephoto.jpg`;
        apiUrl2 = `https://csce315331-07b.onrender.com/static/managerpics/kungfuteabanner.jpg`;
        apiUrl3 = `https://csce315331-07b.onrender.com/static/managerpics/teadrinks.jpg`;
    }

    return (
        <div className="content-container">
            <ManagerNavigationBar/>
            <div className="rounded-carousel-container">
                <Carousel>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={apiUrl2}
                            alt="An image of the KungFuTea logo bar"
                        />
                        <img
                            className="d-block w-100"
                            src={apiUrl3}
                            alt="An assortment of KungFuTea drinks"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 carousel-image"
                            src={apiUrl1}
                            alt="A photograph of the inside of a typical Kung Fu Tea store"
                        />
                        <Carousel.Caption>
                        <p className="dark-text">Hi</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    
                </Carousel>
            </div>
        </div>
    );
    
    
};

export default ManagerHomePage;